import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

const DURATION = 0.25;
const STAGGER = 0.025;

const FlipAnimatedText = ({ words }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000); // Change word every 3 seconds
    return () => clearInterval(interval);
  }, [words.length]);

  const currentWord = words[currentWordIndex].split("");

  return (
    <motion.div
      initial="initial"
      whileHover="hovered"
      className="relative cursor-pointer overflow-hidden whitespace-nowrap text-white mt-10 ms-8  md:ms-12 lg:ms-20 xl:ms-16 4k:ms-28 font-black uppercase text-3xl 4k:text-5xl  "
    >
      <div>
        {currentWord.map((l, j) => (
          <motion.span
            variants={{
              initial: {
                y: 0,
              },
              hovered: {
                y: "-100%",
              },
            }}
            transition={{
              duration: DURATION,
              ease: "easeInOut",
              delay: STAGGER * j,
            }}
            className="inline-block"
            key={`${currentWordIndex}-${j}`}
          >
            {l}
          </motion.span>
        ))}
      </div>
      <div className="absolute inset-0">
        {currentWord.map((l, j) => (
          <motion.span
            variants={{
              initial: {
                y: "100%",
              },
              hovered: {
                y: 0,
              },
            }}
            transition={{
              duration: DURATION,
              ease: "easeInOut",
              delay: STAGGER * j,
            }}
            className="inline-block"
            key={`${currentWordIndex}-${j}`}
          >
            {l}
          </motion.span>
        ))}
      </div>
    </motion.div>
  );
};

export default FlipAnimatedText;
