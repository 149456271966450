import React, { useState, useRef } from "react";
import { Link , useLocation} from "react-router-dom";
import { Facebook, Instagram, Youtube, LinkedIn } from "../assets/icons";
import emailjs from "emailjs-com";
import NotifyButton from "./NotifyButton";
import FooterMatrixText from "./FooterMatrixText";

const footerData = [
  {
    title: "Quick Links",
    links: [
      { name: "Home", path: "/" },
      { name: "About Us", path: "/about-us" },
      { name: "Career", path: "/career" },
    ],
  },
  {
    title: "Services",
    links: [
      { name: "Software Development", path: "/software-development" },
      { name: "Mobile App Development", path: "/mobile-app-development-services" },
      { name: "Digital Marketing", path: "/digital-marketing-services" },
      { name: "PDF Accessibility", path: "/pdf-accessibility" },
      { name: "E-Books Conversion", path: "/ebook-conversion" },
      { name: "Data Entry", path: "/data-entry" },
    ],
  },
  {
    title: "Support Services",
    links: [
      { name: "Web Accessibility", path: "/web-accessibility" },
      { name: "Word Accessibility", path: "/word-accessibility" },
      { name: "Medical Coding", path: "/medical-coding" },
      { name: "E-Publishing", path: "/e-publishing" },
      { name: "XML Conversion", path: "/xml-conversion" },
      { name: "Type Setting", path: "/type-setting" },
      { name: "Copy Editing", path: "/copy-editing" },
    ],
  },
];

const socialIcons = [
  { src: Facebook, alt: "Facebook", link: "https://www.facebook.com/eloiacs" },
  { src: Instagram, alt: "Instagram", link: "https://www.instagram.com/eloiacs_softwa_pvt.ltd/" },
  { src: Youtube, alt: "Youtube", link: "https://www.youtube.com/@eloiacs_softwa" },
  { src: LinkedIn, alt: "LinkedIn", link: "https://www.linkedin.com/company/99473854/admin/dashboard/" },
];

const FooterNew1 = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const formRef = useRef();
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.length === 0) {
      setError(true);
      setSuccess(false);
    } else {
      setError(false);
      sendEmail();
    }
  };

  // const sendEmail = () => {
  //   emailjs
  //     .sendForm(
  //       "service_ot0wd39",
  //       "template_tddvger",
  //       formRef.current,
  //       "e0RTXjhasJQ16WqE7"
  //     )
  //     .then(
  //       () => {
  //         setEmail("");
  //       },
  //       (error) => {
  //         console.error("FAILED...", error.text);
  //       }
  //     );
  // };

  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_ot0wd39",
        "template_tddvger",
        formRef.current,
        "e0RTXjhasJQ16WqE7"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setEmail("");
          setSuccess(true); // Set success message
        },
        (error) => {
          console.log("FAILED...", error.text);
          setError(true);
        }
      );
  };
  return (
    <div className="bg-black text-white pt-7 pb-6 px-3 md:px-14 relative">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between">
        {/* Newsletter Section (First on mobile, Last on desktop) */}
        <div className="w-full lg:w-1/3  lg:mt-0 order-1 lg:order-2 ">
          <div className="mx-3 md:p-6 rounded-lg shadow-lg">
            <h4 className="text-xl 4k:text-4xl text-center text-orange-500 font-bold mb-4">
              Join Our Newsletter
            </h4>
            <form ref={formRef} onSubmit={handleSubmit} className="flex flex-col 4k:mt-20">
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  className={`py-2 px-2 md:px-4 bg-transparent border-b-[3px] text-white 4k:text-3xl outline-none w-full focus:border-orange-500  ${
                    error && !email ? "border-red-500" : "border-gray-900"
                  }`}
                  placeholder="Enter your Email Address"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <NotifyButton onClick={handleSubmit} />
              </div>

              {/* Error message for empty input */}
              {error && !email && (
                <p className="text-red-500 text-sm 4k:text-2xl italic mt-2 ps-3">
                  Email is required.
                </p>
              )}

              {/* Success message */}
              {success && (
                <p className="text-green-500 text-sm italic mt-2 ps-3">
                  Thank you! You've successfully subscribed.
                </p>
              )}
            </form>
          </div>

          {/* Social Icons */}
          <div className="mt-8 flex justify-center space-x-6 4k:space-x-10">
            {socialIcons.map((icon, index) => (
              <a
                key={index}
                href={icon.link}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:opacity-75 transition-opacity hidden md:block"
              >
                <img src={icon.src} alt={icon.alt} className="w-7 h-7 4k:w-14 4k:h-14" />
              </a>
            ))}
          </div>
        </div>

        {/* Links Section (Last on mobile, First on desktop) */}
        <div className="flex flex-col md:flex-row space-y-8 lg:space-y-0 lg:space-x-12 xl:space-y-0 xl:space-x-44 order-2 lg:order-1 ps-5 ">
          {footerData.map((section, index) => (
            <div key={index} className="mt-8 lg:mt-0 w-full lg:w-auto ">
              <h3 className="text-lg 4k:text-3xl font-semibold text-orange-500 mb-4">
                {/* {section.title} */}
                <FooterMatrixText text={section.title} />
                </h3>
              <ul>
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex} className="mb-4 4k:mb-8">
                    <Link
                      to={link.path}
                      className={`4k:text-2xl hover:tracking-wide hover:text-orange-500 transition-colors ${
                        location.pathname === link.path
                          ? "text-orange-500 font-semibold"
                          : "text-white"
                      } `}
                    >
                      {link.name}
                      
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>


        <div className="mt-8 flex justify-center space-x-0 order-3">
            {socialIcons.map((icon, index) => (
              <a
                key={index}
                href={icon.link}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:opacity-75 transition-opacity block md:hidden"
              >
                <img src={icon.src} alt={icon.alt} className="w-7 h-7" />
              </a>
            ))}
          </div>
      </div>
    </div>
  );
};

export default FooterNew1;