import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import caurosel1 from "../../assets/images/caurosel4.jpeg";
import caurosel2 from "../../assets/images/servicecaurosel2.png";
import caurosel3 from "../../assets/images/caurosel3.png";
import image1 from "../../assets/images/eloiacs softwa.jpg";
import image2 from "../../assets/images/caurosel3.png";
import back from "../../assets/images/servicetechnology.jpeg";
import {
  Experienceicon,
  supporticon,
  Softwaredevicon,
  DigitalMarketingicon,
  DataAnalyticsicon,
  CyberSecurityicon,
  UserExperience,
  softwaredevelopmenticon,
  dataentryicon,
  uiuxicon,
  pdficon,
  browsericon,
  androidicon,
  appleicon,
  ioticon,
  accessibiltyicon,
  figmaicon,
} from "../../assets/icons";
import ViewMoreButton from "../../components/ViewMoreButton";
import { getHomeServices, getServices, getServicesCarousel } from "../../networking/ServicesActions";

const ServicesPage = () => {
  useEffect(() => {
    document.title = "Services - Eloiacs Software Pvt Ltd";
  }, []);

  const [showAll, setShowAll] = useState(false);
  const internshipSectionRef = useRef(null);
  const navigate = useNavigate();

  const handleLearnMoreClick = (serviceName) => {
    if (serviceName) {
      navigate(`/${serviceName}`);
    }
  };

  const [servicedata, setservicedata] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    getServicesCarousel()
    .then((res) => {
      const servicepagecode = res.data.code;
      const servicepagestatus = res.data.status;
      const servicepagemessage = res.data.message;
      console.log(servicepagecode, "Service Page code");
      console.log(servicepagestatus, "Service Page status");
      console.log(servicepagemessage, "Service Page message");
      
      const sliceServiceCauroselData = res.data.data.slice(1);
      console.log(sliceServiceCauroselData, "Service Caurosel Data");
      setservicedata(sliceServiceCauroselData);
      console.log(sliceServiceCauroselData, "res data for services")
    })
    .catch((err) => console.log(err));
  }, [])

  
  // Start For Caurosel Section
  const slides = [
    {
      image: caurosel1,
      title: "Full Cycle Software Development",
      heading: "Software IT Services",
      content:
        "We Provide IT services like software development, system integration, and tech support. We create custom solutions to fit your needs and help your business run smoothly. Let us support your tech success!",
      buttondata: "LEARN MORE",
    },
    {
      image: caurosel2,
      title: "We Creating Leading Digital Products",
      heading: "Digital Marketing Services",
      content:
        "We help you reach more people, engage your audience, and grow your business!",
      buttondata: "LEARN MORE",
    },
    {
      image: caurosel3,
      title: "Only High Quality Services",
      heading: "Non IT Services",
      content:
        "We help you improve efficiency and reach your goals with practical solutions. Let us support your business for better performance!",
      buttondata: "LEARN MORE",
    },
  ];



  const nextSlide = () => {
    // if (servicedata.length > 0) {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    // }
  };

  const prevSlide = () => {
    // if (servicedata.length > 0) {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  // }
  };

  const headingVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.6 } },
    exit: { y: 100, opacity: 0, transition: { duration: 0.6 } },
  };

  const contentVariants = {
    hidden: { x: 150, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.6 } },
    exit: { x: -150, opacity: 0, transition: { duration: 0.6 } },
  };

  // useEffect(() => {
  //   const interval = setInterval(nextSlide, 2000);
  //   return () => clearInterval(interval);
  // }, [currentIndex]);

  useEffect(() => {
    if (servicedata.length > 0) {
      // const interval = setInterval(() => {
      //   nextSlide();
      // }, 2000);

      const interval = setInterval(nextSlide, 2000);
  
      // Clear the interval on component unmount or when data changes
      return () => clearInterval(interval);
    }
  }, [servicedata, currentIndex]);

  //End for Caurosel Section

  //Start for About Section

  const text =
    "We pride ourselves on being your trusted partner for software innovation. With a focus on delivering cutting-edge solutions, our experienced team works closely with you to understand your unique needs and objectives.";

  const splitTextIntoWords = (text) => {
    return text.split(/\s+/).map((word, index) => (
      <motion.span
        key={index}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: index * 0.1 }}
        className="inline-block mr-1"
      >
        {word}
      </motion.span>
    ));
  };

  //End for About Section

  //Start for WhyChooseUs Section

  const services = [
    {
      title: "Software Development",
      description:
        "Software Development is the process of designing, coding, testing, and maintaining applications or systems that solve problems, improve efficiency.",
      linkText: "LEARN MORE",
      icons: Softwaredevicon,
    },
    {
      title: "Digital Marketing",
      description:
        "Digital Marketing is the strategic use of online channels, platforms, and technologies to promote products, services engaging with target audiences.",
      linkText: "LEARN MORE",
      icons: DigitalMarketingicon,
    },
    {
      title: "UI/UX Design",
      description:
        "UI/UX Design is the process of crafting intuitive and visually appealing interfaces (UI) while ensuring a seamless, user-centered experience (UX).",
      linkText: "LEARN MORE",
      icons: DataAnalyticsicon,
    },
    {
      title: "PDF Accessibility",
      description:
        "PDF Accessibility refers to the process of making PDF documents usable and navigable by individuals and ensuring they comply with standards such as WCAG and PDF/UA.",
      linkText: "LEARN MORE",
      icons: CyberSecurityicon,
    },
  ];

  //End for WhyChooseUs Section

  //Start for Services Section

  const servicesdatas = [
    {
      id: 1,
      name: "software-development",
      title: "Software Development",
      description:
        "We carry more than just good coding skills. Our experience makes us stand out from other web development. We carry more than just good coding skills. Our experience makes us stand out from other web development.",
      icon: softwaredevelopmenticon,
      buttondata: "READ MORE",
      links: "/software-development",
    },
    {
      id: 2,
      title: "Data Entry",
      name: "data-entry",
      description:
        "Create complex enterprise software, ensure reliable software integration, modernise your legacy system.",
      icon: dataentryicon,
      buttondata: "READ MORE",
      links: "/ServiceDetails/data-entry",
    },
    {
      id: 3,
      title: "UI/UX Design",
      name: "ui-ux-design",
      description:
        "Effective UI/UX design enhances usability, accessibility, and enjoyment, ensuring that users can navigate and achieve their goals effortlessly.",
      icon: uiuxicon,
      buttondata: "READ MORE",
      links: "/ServiceDetails/ui-ux-design",
    },
    {
      id: 4,
      title: "E-Books Conversion",
      name: "ebook-conversion",
      description:
        "We specialize in the scrutiny of manuscripts, ensuring each tale reaches its peak before it graces the hands of eager readers.",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/ebook-conversion",
    },
    {
      id: 5,
      title: "PDF Accessibility",
      name: "pdf-accessibility",
      description:
        "Ensure your documents are accessible to all users with our PDF accessibility services, making information inclusive and compliant.",
      icon: pdficon,
      buttondata: "READ MORE",
      links: "/ServiceDetails/pdf-accessibility",
    },
    {
      id: 6,
      title: "Digital Marketing",
      name: "digital-marketing-services",
      description:
        "Let our digital marketing services be your guide in the extensive world of commerce, leading you to unmatched success. Embrace the future with the wisdom of the past, and see your brand rise to great fame.",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/digital-marketing-services",
    },
    {
      id: 7,
      title: "Mobile App Development",
      name: "mobile-app-development-services",
      description:
        "Stay connected with your customers on the go with our mobile app development services, designed to enhance user engagement and satisfaction.",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/mobile-app-development-services",
    },
    {
      id: 8,
      title: "Artificial Intelligence",
      name: "artificial-intelligence-solutions",
      description:
        "Artificial Intelligence (AI) refers to the simulation of human intelligence in machines designed to think, learn, and solve problems autonomously. AI technologies enable computers to perform tasks that typically require human intelligence, such as understanding language, recognizing patterns, and making decisions",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/artificial-intelligence-solutions",
    },
    {
      id: 9,
      title: "Medical Coding",
      name: "medical-coding",
      description:
        "Simplify complex medical coding processes with our specialized services, optimizing accuracy and efficiency in healthcare settings.",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/medical-coding",
    },
    {
      id: 10,
      title: "XML Conversion",
      name: "xml-conversion",
      description:
        "In our noble halls, we practice the art of XML Conversion, transforming both ancient manuscripts and modern documents into the digital format of XML, suitable for the digital age.",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/xml-conversion",
    },
    {
      id: 11,
      title: "BPO Services",
      name: "bpo-services",
      description:
        "Take advantage of the best BPO services, where hard work and excellence come together to improve your business efforts. Trust in our unwavering commitment to elevate your enterprise to unparalleled heights.",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/bpo-services",
    },
    {
      id: 12,
      title: "Website Development",
      name: "website-development-services",
      description:
        "Experience the transformative impact of a captivating website that brings your brand to life. Embrace the digital era with premium website development services that elevate your online presence.",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/website-development-services",
    },
    {
      id: 13,
      title: "E - Publishing",
      name: "e-publishing",
      description:
        "EPub is compatible with a wide range of e-readers, tablets, and smartphones, ensuring your audience can access your content wherever they are.",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/e-publishing",
    },
    {
      id: 14,
      title: "Type Setting",
      name: "type-setting",
      description:
        "Typesetting is the process of arranging text for clear, organized, and visually appealing presentation in books, articles, and digital formats.",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/type-setting",
    },
    {
      id: 15,
      title: "Web Accessibility",
      name: "web-accessibility",
      description:
        "Web accessibility ensures that websites are usable by everyone, including people with disabilities.",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/web-accessibility",
    },
    {
      id: 16,
      title: "Word Accessibility",
      name: "word-accessibility",
      description:
        "Word accessibility ensures that Word documents are easy to read and navigate for everyone, including people with disabilities",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/word-accessibility",
    },
    {
      id: 17,
      title: "Copy Editing",
      name: "copy-editing",
      description:
        "Copy editing involves reviewing and refining written content to improve clarity, grammar, punctuation, and style",
      icon: UserExperience,
      buttondata: "READ MORE",
      links: "/ServiceDetails/copy-editing",
    },
  ];

  const toggleShowAll = () => {
    setShowAll(!showAll);
    if (showAll && internshipSectionRef.current) {
      internshipSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const displayedJobs = showAll ? servicesdatas : servicesdatas.slice(0, 6);

  //End for Services Section

  //Start for Technology Section

  const techdata = [
    {
      id: 1,
      techname: "WEB",
      img: browsericon,
    },
    {
      id: 2,
      techname: "ANDROID",
      img: androidicon,
    },
    {
      id: 3,
      techname: "IOS",
      img: appleicon,
    },
    {
      id: 4,
      techname: "MARKETING",
      img: ioticon,
    },
    {
      id: 5,
      techname: "ACCESSIBILITY",
      img: accessibiltyicon,
    },
    {
      id: 6,
      techname: "UI/UX",
      img: figmaicon,
    },
  ];

  //End for Technology Section

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <div>
      {/* Caurosel Section */}
      <section>
        <div className="relative w-full overflow-hidden pt-20 md:pt-0 bg-black bg-opacity-15">
          <div
            className="flex transition-transform duration-1000"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {slides.map((slide, index) => (
              <div key={index} className="w-full flex-shrink-0">
                <img
                  loading="lazy"
                  src={slide.image}
                  alt={slide.image}
                  className="w-full h-[30rem] md:h-[35rem] lg:h-[40rem] object-cover"
                />
              </div>
            ))}
          </div>

          {/* {servicedata.length > 0 && servicedata[currentIndex] && ( */}
          <div className="absolute bottom-28 md:bottom-40 md:left-5 lg:bottom-52 lg:left-36 xl:left-44 p-4 sm:p-4 max-w-xs sm:max-w-sm md:max-w-[45rem] text-white text-center md:text-start ">
            <div>
              <motion.div
                key={slides[currentIndex].heading}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={headingVariants}
              >
                <strong className="text-3xl md:text-5xl lg:text-7xl font-extrabold tracking-normal font-Suse">
                  {slides[currentIndex].heading}
                </strong>
              </motion.div>
            </div>
            <motion.div
              className="py-10"
              key={slides[currentIndex].content}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={contentVariants}
            >
              <p className="text-base md:text-xl 4k:text-2xl md:font-semibold font-Inter">
                {slides[currentIndex].content}
              </p>
            </motion.div>
          </div>
          {/* )} */}

          <div className="absolute font-bold bottom-6 left-16 lg:bottom-10 lg:left-40 xl:left-48 flex items-center space-x-2 bg-gray-800 text-white px-3 py-1 rounded-full">
            <button className="px-2 py-1 hover:scale-x-125" onClick={prevSlide}>
              <span>&lt;</span>
            </button>

            <span className="text-lg">
              {`0${currentIndex + 1}/0${slides.length}`}
            </span>

            <button className="px-2 py-1 hover:scale-x-125" onClick={nextSlide}>
              <span>&gt;</span>
            </button>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section>
        <div>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 pt-10 px-8 md:px-4 lg:pt-20 lg:px-16 xl:px-20 4k:py-32 "> */}
          <div className="grid grid-cols-1 md:grid-cols-2 pt-10 px-8 md:px-14 lg:pt-20 lg:px-20 xl:px-[4.5rem] 4k:px-[6.5rem] 4k:py-32 ">
            {/* <div className="col-span-1 lg:px-12 xl:px-24 "> */}
            <div className="col-span-1  ">
              <div className="pt-2">
                <strong className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-Suse">
                  Your Partner for Software Innovation
                </strong>
              </div>
              <div className="py-4">
                <p className="text-justify text-base 4k:text-2xl font-Inter">
                  {splitTextIntoWords(text)}
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 md:gap-x-10 py-5 md:py-10 ">
                <div className="col-span-1 group ">
                  <div className="flex justify-center items-center md:justify-start md:items-start">
                    <img
                      src={Experienceicon}
                      alt="Not Found"
                      className="w-10 h-10"
                    />
                  </div>
                  <p className="font-bold pt-4 pb-4 text-lg 4k:text-xl font-PTSansCaption text-center md:text-start">
                    Experience
                  </p>
                  <div className="relative">
                    <div className="hr-line"></div>
                  </div>
                  <p className="md:text-justify pt-5 pb-1.5 font-Inter 4k:text-xl text-center">
                    With 4 years of pioneering experience in the software
                    industry.
                  </p>
                </div>
                <div className="col-span-1 group ">
                  <div className="flex justify-center items-center md:justify-start md:items-start">
                    <img src={supporticon} alt="" className="w-10 h-10" />
                  </div>
                  <p className="font-bold pt-4 pb-4 text-lg 4k:text-xl  font-PTSansCaption text-center md:text-start">
                    Quick Support
                  </p>
                  <div className="relative">
                    <div className="hr-line"></div>
                  </div>
                  <p className="md:text-justify pt-5 pb-1.5 font-Inter 4k:text-xl text-center">
                    Eloiacs Softwa offers fast, reliable assistance to resolve
                    your IT issues efficiently.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-span-1 relative  xl:ms-20">
              <div>
                <div className="absolute inset-0 z-40">
                  <img
                    src={image1}
                    alt="Not Found"
                    loading="lazy"
                    className=" lg:w-[28rem] lg:h-[20rem] shadow-2xl"
                  />
                </div>
                <div className="relative top-[5rem] md:top-[8rem] lg:top-[14rem]">
                  <img
                    src={image2}
                    alt="Not Found"
                    loading="lazy"
                    className="w-40 h-32 md:w-[13.5rem] md:h-48 z-50 ml-[7rem] md:ml-[7.5rem] lg:ml-[18rem] md:hidden lg:block 4k:hidden"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us */}

      <section>
        <div className="bg-[#221F3C] mt-32 md:mt-0 py-5 md:py-16 md:px-[1rem] lg:px-5 xl:px-10 ">
          <div className="text-white">
            <p className="text-lg 4k:text-xl font-semibold text-center tracking-wider">
              WHY CHOOSE US
            </p>
            <div className="text-2xl md:text-3xl 4k:text-5xl font-bold pt-10 md:pt-4 px-4 md:px-20 lg:px-72">
              <p className="text-center px-3 md:px-0 font-Suse">
                Design the Concept of Your Business Idea Now
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-14 lg:gap-8 xl:gap-14 pt-10 px-8 md:px-10 lg:px-5 xl:px-10 4k:gap-20 4k:px-20 ">
            {services.map((service, index) => (
              <div
                key={index}
                className="grid grid-rows-1  relative border border-white border-opacity-35 pt-4 ps-4 pr-4 4k:ps-8 4k:pr-8 bg-[#2C2C2C] bg-opacity-35 text-white group transition-colors hover:duration-500 hover:bg-white hover:text-black  "
              >
                <div className="row-span-1 font-bold pt-9 ">
                  <p className="text-xl 4k:text-2xl tracking-wider">
                    {service.title}
                  </p>
                </div>
                <div className=" row-span-1 mt-4 font-mono text-justify 4k:text-xl ">
                  <p>{service.description}</p>
                </div>
                <div className=" row-span-1 relative mt-4 py-3 ">
                  <div className="flex justify-end items-end relative z-10">
                    <img src={service.icons} alt="" className="w-12 h-12" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Our Services Section */}
      <section>
        <div>
          <div
            className="pt-2 md:pt-10 px-2 md:px-12 lg:px-[4.8rem] "
            ref={internshipSectionRef}
          >
            <div>
              <p className="font-bold text-2xl md:text-3xl lg:text-4xl pt-4 px-6 md:px-0 font-Suse text-center lg:text-start ">
                We Offer a Wide Variety of IT & Non-IT Services
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pt-10 ">
              {displayedJobs.map((service, index) => (
                <div className="col-span-1 " key={index}>
                  <div className="grid grid-cols-3 lg:px-3 hover:border hover:shadow-xl hover:rounded-xl lg:py-2 ">
                    <div className="col-span-1  grid justify-center items-start ">
                      <div className="hover:bg-orange-400 hover:border w-20 h-20 hover:rounded-full flex items-center justify-center ">
                        <img src={service.icon} alt="" className="w-10 h-10" />
                      </div>
                    </div>

                    <div className="col-span-2 ">
                      <div className="grid grid-rows-1 p-1.5 ">
                        <div className="row-span-1 ">
                          <p className="font-bold text-lg 4k:text-2xl font-Inter md:text-nowrap lg:text-wrap">
                            {service.title}
                          </p>
                        </div>
                        <div className="row-span-1 md:h-[8rem] lg:min-h-[6.5rem] lg:max-h-24 overflow-hidden items-center grid ">
                          <p
                            className=" text-justify first-letter:uppercase first-letter:text-xl first-letter:font-semibold font-Inter 4k:text-xl"
                            title={service.description}
                          >
                            {service.description.length > 90
                              ? service.description.substring(0, 90) + "..."
                              : service.description}
                          </p>
                        </div>
                        <div className="row-span-1 py-3 ">
                          <p
                            className="py-2 text-orange-600 font-medium underline hover:translate-x-1 cursor-pointer 4k:text-xl"
                            onClick={() => handleLearnMoreClick(service.name)}
                          >
                            <MatrixText />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center items-center pt-2 pb-5">
            {/* <p
              className="rounded-full border border-black bg-orange-500 text-black font-bold px-4 py-2 cursor-pointer text-center text-base md:text-lg"
              onClick={toggleShowAll}
            >
              {showAll ? "View Less" : "View More"}
            </p> */}
            <ViewMoreButton
              onClick={toggleShowAll}
              buttonText={showAll ? "View Less" : "View More"}
            />
          </div>
        </div>
      </section>

      {/* Technology Index Section */}
      <section>
        <div className="relative py-20 mb-5">
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${back})` }}
          ></div>
          <div className="relative text-white py-10 bg-opacity-65 bg-black">
            <p className="text-xl font-semibold text-center">
              TECHNOLOGY INDEX
            </p>
            <div className="px-8 md:px-24 lg:px-40 xl:px-80 ">
              <p className="text-2xl md:text-3xl lg:text-4xl 4k:text-5xl font-extrabold mt-4 text-center tracking-normal font-Suse">
                We Deliver Solution with the Goal of Trusting Relationships
              </p>
            </div>

            <div className="mt-12 px-7 md:px-[6.5%] lg:px-[5%] xl:px-[5.2%] ">
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-y-5 gap-x-5 lg:gap-x-3 xl:gap-x-5 md:gap-y-5 lg:gap-y-0 rounded-md">
                {techdata.map((item) => (
                  <div className="border hover:border-none py-8 md:py-10 hover:bg-orange-500">
                    <div className="flex justify-center items-center">
                      <img
                        src={item.img}
                        alt="Not Found"
                        className="w-10 h-10 4k:w-14 4k:h-14"
                      />
                    </div>
                    <p className="text-center font-semibold text-base md:text-lg 4k:text-2xl mt-3 4k:mt-8 font-Suse">
                      {item.techname}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesPage;

const TARGET_TEXT = "READ MORE";
const CYCLES_PER_LETTER = 2;
const SHUFFLE_TIME = 50;

const CHARS = "!@#$%^&*():{};|,.<>/?";

const MatrixText = () => {
  const intervalRef = useRef(null);
  const [text, setText] = useState(TARGET_TEXT);

  const shuffle = () => {
    let pos = 0;
    intervalRef.current = setInterval(() => {
      const shuffled = TARGET_TEXT.split("")
        .map((char, index) => {
          if (pos / CYCLES_PER_LETTER > index) {
            return char;
          }

          const randomCharIndex = Math.floor(Math.random() * CHARS.length);
          const randomChar = CHARS[randomCharIndex];

          return randomChar;
        })
        .join("");

      setText(shuffled);
      pos++;

      if (pos >= TARGET_TEXT.length * CYCLES_PER_LETTER) {
        stopShuffle();
      }
    }, SHUFFLE_TIME);
  };

  const stopShuffle = () => {
    clearInterval(intervalRef.current || undefined);
    setText(TARGET_TEXT);
  };
  return (
    <span
      className="font-mono"
      onMouseEnter={shuffle}
      onMouseLeave={stopShuffle}
    >
      {text}
    </span>
  );
};
