import React, { useState, useRef } from "react";

const CYCLES_PER_LETTER = 2;
const SHUFFLE_TIME = 50;
const CHARS = "!@#$%^&*():{};|,.<>/?";

const FooterMatrixText = ({ text }) => {
  const intervalRef = useRef(null);
  const [displayText, setDisplayText] = useState(text);

  const shuffle = () => {
    let pos = 0;
    intervalRef.current = setInterval(() => {
      const shuffled = text.split("")
        .map((char, index) => {
          if (pos / CYCLES_PER_LETTER > index) {
            return char;
          }

          const randomCharIndex = Math.floor(Math.random() * CHARS.length);
          const randomChar = CHARS[randomCharIndex];

          return randomChar;
        })
        .join("");

      setDisplayText(shuffled);
      pos++;

      if (pos >= text.length * CYCLES_PER_LETTER) {
        stopShuffle();
      }
    }, SHUFFLE_TIME);
  };

  const stopShuffle = () => {
    clearInterval(intervalRef.current || undefined);
    setDisplayText(text);
  };

  return (
    <span
      className=" inline-block h-8 leading-none"  // Ensure consistent height and line-height
      style={{ width: `${text.length}ch` }} // Set a fixed width based on the number of characters
      onMouseEnter={shuffle}
      onMouseLeave={stopShuffle}
    >
      {displayText}
    </span>
  );
};

export default FooterMatrixText;
