import React, { useEffect, useState } from "react";
import careerhome_img from "../../assets/images/website_photo2.jpg";
import { quoteIcon } from "../../assets/icons";
import { getVoicesOfEloiacs } from "../../networking/HomeActions";

const careertestimonials = [
  {
    text: "I had the opportunity of working at Eloiacs softwa, where I found an exceptional work environment and a truly rewarding experience.",
    author: "- Mahalekshmi, Eloiacs",
    image:
      "https://t4.ftcdn.net/jpg/05/60/50/29/240_F_560502955_BrRaUBXIOZlzeU5u2XWoGuvupDmB2ayN.jpg",
  },
  {
    text: "Eloiacs had strong relationships with coworkers. Overall nice place to work.",
    author: "- KrishnaDevi, Eloiacs",
    image:
      "https://t4.ftcdn.net/jpg/05/60/50/29/240_F_560502955_BrRaUBXIOZlzeU5u2XWoGuvupDmB2ayN.jpg",
  },
  {
    text: "I am doing Digital Marketing Internship here! I gained hands-on experience in SEO, content creation, and social media.",
    author: "- M K Raja, Eloiacs",
    image:
      "https://t4.ftcdn.net/jpg/05/60/50/29/240_F_560502955_BrRaUBXIOZlzeU5u2XWoGuvupDmB2ayN.jpg",
  },
];

const CareerSection = () => {
  const imageUrl = careerhome_img;
  const [activeIndex, setActiveIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const [data, setData] = useState([]);

  // useEffect(() => {
  //   getVoicesOfEloiacs().then(res => {  
  //     const voicecode = res.data.code;
  //     const voicestatus = res.data.status;
  //     const voicemessage = res.data.message;
  //     console.log(voicecode, "Voices code");
  //     console.log(voicestatus, "Voices status");
  //     console.log(voicemessage, "Voices message");
          
  //     setData(res.data.data)
  //     console.log(res.data.data);
  //   }).catch(err => console.log(err))
  // }, [])

  const handleDotClick = (index) => {
    setFade(true);
    setTimeout(() => {
      setActiveIndex(index);
      setFade(false);
    }, 300);
  };

  // console.log(data[0]);
  

  

  return (
    <div>
      <div className=" pt-10 pb-8 md:pb-20">
        <div className="text-white">
          <p className="text-xl md:text-3xl text-center uppercase text-orange-400 font-semibold tracking-wide relative pointer-events-none">
            {/* {data[0]?.title} */}
            “Voices of Eloiacs”
          </p>

          <div>
            <p className="text-lg md:text-2xl px-8 md:px-20 lg:px-52 xl:px-[30rem] text-center pt-5 font-Inter relative pointer-events-none">
            {/* {data[0]?.description} */}
            Every Eloiacion is the navigator of our clients’ digital transformation

            </p>
          </div>
        </div>
        <div>
          <div className="relative flex justify-center items-center pt-8 pointer-events-none">
            <img
              // src={data[0]?.imageUrl}
              src={careerhome_img}
              alt=""
              className="w-full md:w-[60%] h-60 md:h-[20rem] lg:h-[25rem] object-cover"
            />

            <div className="absolute bottom-2 left-3 md:bottom-4 lg:bottom-9 md:left-40 lg:left-[20rem] 4k:left-[41rem] ">
              <p className="text-white text-center font-medium text-base md:text-lg lg:text-xl tracking-wide">
                {/* {data[0]?.imageDescription} */}
                Life of Eloiacs
              </p>
            </div>
          </div>

          <div className="relative flex md:justify-end md:items-end md:pe-28  md:-mt-28 z-40">
            <div className="flex-col shadow-2xl bg-gray-200 md:w-[50%] lg:w-[30%] p-2 border  rounded-md outline outline-orange-400">
              <p className="text-center pt-3 lg:pt-3 pb-2 uppercase font-semibold text-base">
                What our people say
              </p>

              <div
                className={`grid grid-cols-3 gap-x-2 transition-opacity duration-300  ${
                  fade ? "opacity-0" : "opacity-100"
                }`}
              >
                <div className="col-span-2 ">
                  <div className="relative">
                    <div className="absolute z-40 bottom-8  left-3 ">
                      <img
                        src={quoteIcon}
                        alt=""
                        className="w-10 h-10 hidden md:block"
                      />
                    </div>
                  </div>
                  <p className="italic ps-3">
                    "{careertestimonials[activeIndex].text}"
                  </p>
                  <p className="italic pt-1 ps-3">
                    {careertestimonials[activeIndex].author}
                  </p>
                </div>

                <div className="col-span-1 flex justify-center items-center ">
                  <img
                    src={careertestimonials[activeIndex].image}
                    className="w-24 h-24 lg:w-24 lg:h-24 rounded-full z-40 border-opacity-50"
                    alt="Not Found"
                  />
                </div>
              </div>

              <div className="flex justify-center my-3 lg:my-2 pt-1">
                {careertestimonials.map((_, index) => (
                  <span
                    key={index}
                    onClick={() => handleDotClick(index)}
                    className={`w-2 h-2 mx-1 rounded-full cursor-pointer ${
                      activeIndex === index ? "bg-gray-800" : "bg-gray-400"
                    }`}
                  ></span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerSection;
