import React from "react";
import { JobDescription } from "../../constants";
import { Link } from "react-router-dom";

const Description = () => {
  return (
    <div className="relative w-full">
      {JobDescription.map((data) => (
        <div key={data.id}>
          <div className="relative">
            <img
              src={data.imgUrl}
              alt="Description "
              className="w-full h-85vh object-cover"
            />
            <div className="absolute top-26% md:left-30% lg:left-34% xl:left-40% tracking-wider text-center text-slate-50  font-DMsans ">
              <p className="text-25px font-bold px-10 md:px-0">{data.title}</p>
              <div className="flex flex-col md:flex-row">
                <p className="py-9  font-medium text-15px tracking-wider">
                  {data.description1}
                  <span className="hidden md:inline-block">| </span>
                </p>
                <div className="flex justify-center">
                  <p className="md:ps-1 md:pt-9"> {data.description2} </p>
                  {data.descriptionImgUrls.map((imgUrl) => (
                    <img
                      src={imgUrl.desUrl}
                      alt="Facebook "
                      width={22}
                      height={30}
                      className="mx-1"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="px-10 md:px-16 lg:px-28 pt-10 md:pt-50px">
            <div className="md:flex w-full justify-between">
              <div>
                <p className="font-bold pb-5 text-xl">Job Description</p>
                {data.contents.map((items) => (
                  <div key={items.id} className="font-DMsans font-medium">
                    <p className="pb-5">{items.title}</p>
                    <ul className="pb-5">
                      {items.subContents.map((subContent) => (
                        <li className="text-15px " key={subContent.id}>
                          {subContent.Sino ? subContent.Sino : ""}{" "}
                          {subContent.text}{" "}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="py-5 lg:py-0">
                <Link to={"/login"}>
                  <button className="bg-career-orange sticky top-10 text-slate-50 min-w-108px text-base font-semibold tracking-wider rounded-md font-DMsans min-h-43px shadow-bottom-only">
                    APPLY
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Description;
