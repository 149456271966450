import PHONE from "../../assets/icons/PHONE.svg";

const PulseAnimation = () => {
  return (
    <div className="relative h-96 w-96 mx-auto">
      <div
        className="pulse absolute top-0 bottom-0 left-20 right-0 m-auto w-20 h-20 rounded-full grid place-items-center bg-gradient-to-br from-orange-600 to-orange-700 text-white text-6xl"
        style={{
          animation: "pulse 2s ease-out infinite",
        }}
      >
        <i className="fas fa-bell"></i>
      </div>
      <div
        className="pulse absolute top-0 bottom-0 left-20 right-0 m-auto w-16 h-16 rounded-full grid place-items-center bg-gradient-to-br from-orange-600 to-orenge-700 text-white text-6xl"
        style={{
          animation: "pulse 2s ease-out infinite",
        }}
      >
        <i className="fas fa-bell"></i>
      </div>
      <div
        className="pulse absolute top-0 bottom-0 left-20 right-0 m-auto w-12 h-12 rounded-full grid place-items-center bg-gradient-to-br  from-orange-600 to-orenge-700 text-white text-6xl"
        style={{
          animation: "pulse 2s ease-out infinite",
        }}
      >
        <i className="fas fa-bell"></i>
      </div>
      <div className="relative h-96 w-96 mx-auto">
        <div className="pulse absolute top-0 bottom-0 left-20 right-0 m-auto w-14 h-14 rounded-full grid place-items-center bg-gradient-to-br from-orange-600 to-orenge-700 text-white text-6xl">
          <i className="fas fa-bell"></i>
          <div className="pb-3">
            <img className="text-black w-5 " src={PHONE} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PulseAnimation;
