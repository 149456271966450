import React from "react";
import "./Loader.css"

const LoaderDemo = () => {
  return (
    <div
      className="flex justify-center items-center fixed inset-0 bg-gray-400 bg-opacity-70 backdrop-blur-md"
      style={{ zIndex: 60 }}
    >
      <div className="flex space-x-3  ">
        {/* Ball 1 */}
        <div className="w-5 h-5 bg-orange-500 bg-opacity-65 rounded-full animate-bounce" />

        {/* Ball 2 */}
        <div className="w-5 h-5 bg-orange-500 bg-opacity-65 rounded-full animate-bounce animation-delay-200" />

        {/* Ball 3 */}
        <div className="w-5 h-5 bg-orange-500 bg-opacity-65 rounded-full animate-bounce animation-delay-400" />
      </div>
    </div>
  );
};

export default LoaderDemo;
