import React from 'react';
import { ReactComponent as JavaIcon } from '../assets/icons/java-svgrepo-com.svg';
import { ReactComponent as ReactIcon } from '../assets/icons/react-svgrepo-com.svg';
import { ReactComponent as Android } from '../assets/icons/android-color-svgrepo-com.svg';
import { ReactComponent as IOS } from '../assets/icons/apple-svgrepo-com.svg';
import { ReactComponent as NodeIcon } from '../assets/icons/figma-svgrepo-com (2).svg';
import { ReactComponent as Github } from '../assets/icons/accessibility-circle-solid-svgrepo-com (1).svg';
import "./OrbitCircle.css";

const OrbitaryCircle = () => {
  return (
    <div className="flex justify-center items-center h-screen border border-red-500 z-30">
      {/* Outer Circle */}
      <div className="relative w-80 h-80 lg:w-[22rem] lg:h-[22rem] xl:w-[26rem] xl:h-[26rem] 4k:w-[40rem] 4k:h-[40rem] border border-gray-700 rounded-full flex justify-center items-center animate-antispin-slow z-20">
        <div className="absolute w-full h-full rounded-full border border-transparent" />

        {/* Outer Icons */}
        {/* Python Icon (left) */}
        <div className="absolute top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2">
          <div className='counter-antispin'>
            <JavaIcon className="w-12 h-12 xl:w-14 xl:h-14 4k:w-24 4k:h-24" />
          </div>
        </div>
        {/* React Icon (right) */}
        <div className="absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2">
          <div className='counter-antispin'>
            <ReactIcon className="w-12 h-12 xl:w-14 xl:h-14 4k:w-24 4k:h-24" />
          </div>
        </div>
        {/* Android Icon (top) */}
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className='counter-antispin'>
            <Github className="w-12 h-12 xl:w-14 xl:h-14 4k:w-24 4k:h-24" />
          </div>
        </div>
        {/* Notion Icon (bottom) */}
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
          <div className='counter-antispin'>
            <NodeIcon className="w-12 h-12 xl:w-14 xl:h-14 4k:w-24 4k:h-24" />
          </div>
        </div>
      </div>

      <div className="absolute w-44 h-44 4k:w-64 4k:h-64 border border-gray-700 rounded-full flex justify-center items-center animate-spin-slow z-20">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="counter-spin">
              <IOS className="w-12 h-12 4k:w-16 4k:h-16" />
            </div>
          </div>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
            <div className="counter-spin">
              <Android className="w-12 h-12 4k:w-16 4k:h-16" />
            </div>
          </div>
        </div>


      {/* Center Text */}
      <div className="absolute text-3xl lg:text-5xl font-bold z-40 bg-gradient-to-b from-orange-800 to-orange-300 bg-clip-text text-transparent tracking-wider font-Suse opacity-40">
        ELOIACS
      </div>
    </div>
  );
};

export default OrbitaryCircle;
