import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import Button from "../../components/Button";
import DragModal from "../../components/DragModal";
import Form from "../hero/Form";
import rightarrowbullet from "../../assets/icons/right-arrow-next-svgrepo-com.svg";
import backgroundimage1 from "../../assets/images/websitedevelopement.jpg";
import backgroundimage2 from "../../assets/images/dataentry_banner.png";
import backgroundimage3 from "../../assets/images/ui_uxdesign.jpg";
import backgroundimage4 from "../../assets/images/e-book_banner.jpg";
import backgroundimage5 from "../../assets/images/pdfaccessibility.jpg";
import backgroundimage6 from "../../assets/images/digitalmarketing.jpg";
import webaccessibility_banner from "../../assets/images/webaccessibility_banner.png";
import digitalmarketing_banner from "../../assets/images/digitalmarketing_banner.png";
import ai_banner from "../../assets/images/AI_banner.jpg";
import mobileapp_banner from "../../assets/images/mobile-app_banner.jpg";
import medicalcoding_banner from "../../assets/images/medicalCoding_banner.jpg";
import xmlconversion_banner from "../../assets/images/xmlconversion_banner.png";
import bposervices_banner from "../../assets/images/bposervices_banner.jpg";
import websitedev_banner from "../../assets/images/websitedevelopment_banner.png";
import wordaccessibility_banner from "../../assets/images/wordaccessibility_banner.png";
import copyediting_banner from "../../assets/images/copyediting_banner.png";
import typesetting_banner from "../../assets/images/typesetting_banner.png";

import softwaredev1 from "../../assets/images/software development1.webp";
import softwaredev2 from "../../assets/images/software development2.webp";
import softwaredev3 from "../../assets/images/software development3.webp";
import softwaredev4 from "../../assets/images/software development4.webp";
import softwaredev5 from "../../assets/images/software development5.webp";
import softwaredev6 from "../../assets/images/software development6.webp";
import softwaredev7 from "../../assets/images/software development 7.webp";
import softwaredev8 from "../../assets/images/software developmeny 8.webp";

import digitalmarketing1 from "../../assets/images/digital-marketing-1.webp";
import digitalmarketing2 from "../../assets/images/digital-marketing2.webp";
import digitalmarketing3 from "../../assets/images/digital marketing 3.webp";
import digitalmarketing4 from "../../assets/images/digital marketing 4.webp";
import digitalmarketing5 from "../../assets/images/digital marketing 5.webp";
import digitalmarketing6 from "../../assets/images/digital marketing 6.webp";
import digitalmarketing7 from "../../assets/images/digital marketing 7.webp";
import digitalmarketing8 from "../../assets/images/digital marketing 8.webp";

import dataentry1 from "../../assets/images/data entry 1.webp";
import dataentry2 from "../../assets/images/data entry 2.webp";
import dataentry3 from "../../assets/images/data entry 3.webp";
import dataentry4 from "../../assets/images/data entry 4.webp";
import dataentry5 from "../../assets/images/data entry 5.webp";
import dataentry6 from "../../assets/images/data entry 6.webp";
import dataentry7 from "../../assets/images/data entry 7.webp";
import dataentry8 from "../../assets/images/data entry 8.webp";

import ui_ux1 from "../../assets/images/ui ux design 1.webp";
import ui_ux2 from "../../assets/images/ui ux design 2.webp";
import ui_ux3 from "../../assets/images/ui ux design3.webp";
import ui_ux4 from "../../assets/images/ui ux design 4.webp";
import ui_ux5 from "../../assets/images/ui ux design 5.webp";
import ui_ux6 from "../../assets/images/ui ux design 6.webp";
import ui_ux7 from "../../assets/images/ui ux design 7.webp";
import ui_ux8 from "../../assets/images/ui ux design 8.webp";

import ebook1 from "../../assets/images/ebook conversion 1.webp";
import ebook2 from "../../assets/images/ebook conversion 2.webp";
import ebook3 from "../../assets/images/ebook conversion 3.webp";
import ebook4 from "../../assets/images/ebook conversion 4.webp";
import ebook5 from "../../assets/images/ebook conversion 5.webp";
import ebook6 from "../../assets/images/ebook conversion 6.webp";
import ebook7 from "../../assets/images/ebook conversion 7.webp";
import ebook8 from "../../assets/images/ebook conversion 8.webp";

import pdf1 from "../../assets/images/pdf accessibility 1.webp";
import pdf2 from "../../assets/images/pdf accessibility 2.webp";
import pdf3 from "../../assets/images/pdf accessibility 3.webp";
import pdf4 from "../../assets/images/pdf accessibility 4.webp";
import pdf5 from "../../assets/images/pdf accessibility 5.webp";
import pdf6 from "../../assets/images/pdf aceesibility 6.webp";
import pdf7 from "../../assets/images/pdf accessibility 7.webp";
import pdf8 from "../../assets/images/pdf accessibility 8.webp";

import mobileapp1 from "../../assets/images/mobile app  development 1.webp";
import mobileapp2 from "../../assets/images/mobile app development 2.webp";
import mobileapp3 from "../../assets/images/mobile app development 3.webp";
import mobileapp4 from "../../assets/images/mobile app development 4.webp";
import mobileapp5 from "../../assets/images/mobile development 5.webp";
import mobileapp6 from "../../assets/images/mobile app development 6.webp";
import mobileapp7 from "../../assets/images/mobile ap development 7.webp";
import mobileapp8 from "../../assets/images/mobile app development 8.webp";

import medicalcoding1 from "../../assets/images/medical coding 1.webp";
import medicalcoding2 from "../../assets/images/medical coding 2.webp";
import medicalcoding3 from "../../assets/images/medical coding 3.webp";
import medicalcoding4 from "../../assets/images/medical coding 4.webp";
import medicalcoding5 from "../../assets/images/medical coding 5.webp";
import medicalcoding6 from "../../assets/images/medical coding 6.webp";
import medicalcoding7 from "../../assets/images/medical coding 7.webp";
import medicalcoding8 from "../../assets/images/medical coding 8.webp";

import artificialIntelligence1 from "../../assets/images/artificial intelligence 1.webp";
import artificialIntelligence2 from "../../assets/images/artificial intelligence 2.webp";
import artificialIntelligence3 from "../../assets/images/artificial intelligence 3.webp";
import artificialIntelligence4 from "../../assets/images/artificial intelligence 4.webp";
import artificialIntelligence5 from "../../assets/images/artificial intelligence 5.webp";
import artificialIntelligence6 from "../../assets/images/artificial intelligence 6.webp";
import artificialIntelligence7 from "../../assets/images/artificial intelligence 7.webp";
import artificialIntelligence8 from "../../assets/images/artificial intelligence 8.webp";

import wesbitedev1 from "../../assets/images/WEBSITE DEVELOPMENT 1.webp";
import wesbitedev2 from "../../assets/images/WEBSITE DEVELOPMENT 2.webp";
import wesbitedev3 from "../../assets/images/WEBSITE DEVELOPMENT 3.webp";
import wesbitedev4 from "../../assets/images/WEBSITE DEVELOPMENT 4.webp";
import wesbitedev5 from "../../assets/images/WEBSITE DEVELOPMENT 5.webp";
import wesbitedev6 from "../../assets/images/WEBSITE DEVELOPMENT 6.webp";
import wesbitedev7 from "../../assets/images/WEBSITE DEVELOPMENT 7.webp";
import wesbitedev8 from "../../assets/images/WEBSITE DEVELOPMENT 8.webp";

import bpo_services1 from "../../assets/images/BPO SERVICES 1.webp";
import bpo_services2 from "../../assets/images/BPO SERVICES 2.webp";
import bpo_services3 from "../../assets/images/BPO SERVICES 3.webp";
import bpo_services4 from "../../assets/images/BPO SERVICES 4.webp";
import bpo_services5 from "../../assets/images/BPO SERVICES 5.webp";
import bpo_services6 from "../../assets/images/BPO SERVICES 6.webp";
import bpo_services7 from "../../assets/images/BPO SERVICES 7.webp";
import bpo_services8 from "../../assets/images/BPO SERVICES 8.webp";

import xml1 from "../../assets/images/XML CONVERSION 1.webp";
import xml2 from "../../assets/images/XML CONVERSION 2.webp";
import xml3 from "../../assets/images/XML CONVERSION 3.webp";
import xml4 from "../../assets/images/XML CONVERSION 4.webp";
import xml5 from "../../assets/images/XML CONVERSION 5.webp";
import xml6 from "../../assets/images/XML CONVERSION 6.webp";
import xml7 from "../../assets/images/XML CONVERSION 7.webp";
import xml8 from "../../assets/images/XML CONVERSION 8.webp";

import copyediting1 from "../../assets/images/COPY DEDITING 1.webp";
import copyediting2 from "../../assets/images/COPY DEDITING 2.webp";
import copyediting3 from "../../assets/images/COPY DEDITING 3.webp";
import copyediting4 from "../../assets/images/COPY DEDITING 4.webp";

import typediting1 from "../../assets/images/TYPE SETTING 1.webp";
import typediting2 from "../../assets/images/TYPE SETTING 2.webp";
import typediting3 from "../../assets/images/TYPE SETTING 3.webp";
import typediting4 from "../../assets/images/TYPE SETTING 4.webp";

import web_accessibility1 from "../../assets/images/web accessibility 1.webp";
import web_accessibility2 from "../../assets/images/web accessibility 2.webp";
import web_accessibility3 from "../../assets/images/web accessibility 3.webp";
import web_accessibility4 from "../../assets/images/web accessibility 4.webp";

import word_accessibility1 from "../../assets/images/word accessibiltity 1.webp";
import word_accessibility2 from "../../assets/images/word accessibiltity 2.webp";
import word_accessibility3 from "../../assets/images/word accessibiltity 3.webp";
import word_accessibility4 from "../../assets/images/word accessibiltity 4.webp";

import e_publishing1 from "../../assets/images/E PUBLISHING 1.webp";
import e_publishing2 from "../../assets/images/E PUBLISHING 2.webp";
import e_publishing3 from "../../assets/images/E PUBLISHING 3.webp";
import e_publishing4 from "../../assets/images/E PUBLISHING 4.webp";

const headingVariants = {
  hidden: { x: -200, opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { duration: 0.6 } },
  exit: { x: 100, opacity: 0, transition: { duration: 0.6 } },
};

const serviceData = {
  "software-development": {
    title: "SOFTWARE DEVELOPMENT",
    headTitle: "Software Development Services",
    backgroundImage: backgroundimage1,
    description: "Detailed description of Website Development services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1:
      "Unleashing the Power of Code: The Next Era of Software Development",
    servicedescription1:
      "Software development is a comprehensive process that involves designing, creating, testing, and maintaining software applications and systems. It begins with understanding and documenting user requirements to ensure the software will meet their needs. The development phase then translates these requirements into a detailed design and architecture, followed by writing and coding the software.",
    listItems1: [
      "Creating components that can be reused in different projects.",
      "Designing software to handle growth in users or data.",
      "Ensuring code is easy to update and manage.",
      "Enhancing software speed and efficiency.",
      "Framework for managing complex projects.",
      "Combining development and operations for continuous delivery and integration.",
    ],
    section1img1: softwaredev1,
    section1img2: softwaredev2,
    section1img3: softwaredev3,
    section1img4: softwaredev4,

    serviceheading2: "We Code, We Create, We Deliver",
    servicedescription2:
      "UI/UX design focuses on creating intuitive, engaging, and aesthetically pleasing interfaces for digital products. UI (User Interface) design involves crafting the visual elements of a product, such as layouts, buttons, and color schemes, to ensure a cohesive and appealing look. UX (User Experience) design, on the other hand, is concerned with the overall experience a user has when interacting with a product, including usability, accessibility, and satisfaction.",
    listItems2: [
      "Focusing on the needs and preferences of the end user.",
      "Maintaining uniformity in design elements and interactions.",
      "Ensuring the design is usable by people with various disabilities.",
      "Keeping the design intuitive and free of unnecessary elements.",
      "Basic layout sketches outlining structure and content.",
      "Detailed and static visual representations of the final design.",
      "Interactive versions of the design for user testing and feedback.",
    ],
    section2img1: softwaredev5,
    section2img2: softwaredev6,
    section2img3: softwaredev7,
    section2img4: softwaredev8,
  },
  "data-entry": {
    title: "DATA ENTRY",
    headTitle: "Data Entry Services",
    backgroundImage: backgroundimage2,
    description: "Detailed description of Data Entry services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1: "Why Choose Eloiacs Softwa for Data Entry Services?",
    servicedescription1:
      "In today's data-driven world, accurate and efficient data management is crucial for business success. Eloiacs Softwa Private Limited, a trusted IT solutions provider, offers comprehensive data entry services designed to help businesses organize, manage, and utilize their data effectively. With our expertise, your organization can focus on core activities while we handle the complexities of data entry with precision and speed.",
    listItems1: [
      "Our skilled data entry professionals meticulously handle your data, ensuring that every entry is correct and error-free.",
      "Time is of the essence in business, and we value that.",
      "You can trust us to safeguard your data throughout the entire process.",
      "Our team is experienced in handling various data formats and can customize our services to meet your specific needs.",
      "Eloiacs Softwa allows you to reduce operational costs while maintaining high standards of accuracy and efficiency.",
      "We adapt to your requirements, offering solutions that fit your business.",
    ],
    section1img1: dataentry1,
    section1img2: dataentry2,
    section1img3: dataentry3,
    section1img4: dataentry4,

    serviceheading2: "Our Data Entry Services Include",
    servicedescription2:
      "Outsourcing data entry services to Eloiacs Softwa allows you to reduce operational costs while maintaining high standards of accuracy and efficiency. We offer competitive pricing without compromising on quality, making our services a cost-effective solution for your data management needs.",
    listItems2: [
      "Online Data Entry: Entering data directly into your online systems or databases.",
      "Offline Data Entry: Handling large volumes of data in offline formats, such as spreadsheets or text documents.",
      "Data Conversion: Converting data from one format to another, such as from paper documents to digital files.",
      "Data Cleaning and Validation: Ensuring that your data is accurate, up-to-date, and free of errors.",
      "Survey Data Entry: Processing survey responses quickly and accurately for analysis.",
    ],
    section2img1: dataentry5,
    section2img2: dataentry6,
    section2img3: dataentry7,
    section2img4: dataentry8,
  },
  "ui-ux-design": {
    title: "UI/UX DESIGN",
    headTitle: "UI/UX Design",
    backgroundImage: backgroundimage3,
    description: "Detailed description of UI/UX Design services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1: "UI/UX Design - Every Detail matters",
    servicedescription1:
      "Eloiacs Softwa Private Limited is a leading UI/UX design services company based out of Nagercoil which aims to deliver brilliant, unique and robust solutions that boast superior usability. Experience in current aesthetics and advanced tools make Eloiacs Softwa proficient in developing and implementing solutions improving customer experience and company’s outcomes.",
    listItems1: [
      "Agile & Collaborative Approach: Eloiacs operates under a close-knit collaboration with clients shall be open and flexible in terms of the design and feedback cycles.",
      "Cross-Platform Expertise: We develop well integrated and harmonious UI that are compatible and perform well on web, mobile, desktop.",
      "Data-Driven Design: Optimizing branding and aesthetics of products is always predicated on real-world data from the users, analytics and various tests.",
    ],
    section1img1: ui_ux1,
    section1img2: ui_ux2,
    section1img3: ui_ux3,
    section1img4: ui_ux4,

    serviceheading2: "Why Partner with Eloiacs Softwa?",
    servicedescription2:
      "Eloiacs Softwa Private Limited is famed for our great appreciation for both creativeness while developing the appearance of the software and for functional solutions and user interface/user experience points of view. Having our office in the vibrant region of Nagercoil, we have a truly talented team of designers and strategists who are ready to provide unique and truly beautiful design solutions and at the same time – increase the level of satisfaction of users and the growth rate of business.",
    listItems2: [
      "User-Centered Design Focus: Eloiacs Software addresses the issue of usability through creating interface designs that reflect user wants and typical usage patterns.",
      "End-to-End Service: Combining, scope of services offered by Eloiacs includes wire framing and prototyping, design, post-design and after-launch support.",
      "Innovative & Custom Solutions: We design and implement unique UI/UX interfaces that not only provide the appearance that is in line with your brand’s identity, but also with functionality.",
      "Experienced UI/UX Professionals: This guarantees that our team of experts adhere to standard design procedures that meet best practices today with reference to current trends in design and usability.",
    ],
    section2img1: ui_ux5,
    section2img2: ui_ux6,
    section2img3: ui_ux7,
    section2img4: ui_ux8,
  },
  "ebook-conversion": {
    title: "E-BOOKS CONVERSION",
    headTitle: "E-Book Conversion",
    backgroundImage: backgroundimage4,
    description: "Detailed description of E-Books Conversion services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1:
      "Ebook Conversion - From print to pixel-bringing your book to digital life",
    servicedescription1:
      "Eloiacs Softwa Private Limited is an exceptional eBook conversion providers whose main goal is to turn customers’ documents into exceptional, well-formatted eBooks that are accessible by persons with disabilities. No matter whether you have a manuscript or a PDF or any type of file, our team can handle the conversion process and give you a variety of ebook formats such as epubs, Kindle mobi and fixed layout for all the most popular e-readers and devices.",
    listItems1: [
      "Professional Quality: By doing this we ensure that your eBook is perfect regardless of the device used in the viewing or reading process.",
      "Various Formats: We turn your book into all usual eBooks formats: EPUB, MOBI, and PDF.",
      "Attention to Detail: Formatting and layout are given much attention to ensure that the content does not contain any mistake.",
      "Custom Design: For the convenience of our clients we provide design choices according to their brand and create an appealing eBook.",
      "Interactive Features: We also attach clickable links, multimedia as well as place bookmarks for a better read.",
    ],
    section1img1: ebook1,
    section1img2: ebook2,
    section1img3: ebook3,
    section1img4: ebook4,

    serviceheading2: "Partner with us!",
    servicedescription2:
      "We convert your content into various formats, such as EPUB, MOBI, and PDF, so that your end product is always equally appealing on any device. Don’t worry about the format, layout, or even hypertext - like links - because we do it for the eBook to be ready for the readers. It’s faster, more precise, and cheaper, and we will be with you from the time of conversion to distribution. Get your book online without the stress and in the best way possible with us as your partners.",
    listItems2: [
      "Error-Free: In an eBook we do proofreading to ensure that it is free from mistakes as you wish it to be.",
      "SEO-Friendly: It appears in search so we optimize your eBook to make it easily found.",
      "Full Support: We assist in every stage right from the basic process of creating the product to distribution.",
      "Affordable Prices: Thus, we provide great eBook conversion services at rather low prices.",
    ],
    section2img1: ebook5,
    section2img2: ebook6,
    section2img3: ebook7,
    section2img4: ebook8,
  },
  "pdf-accessibility": {
    title: "PDF ACCESSIBILITY",
    headTitle: "Pdf Accessibility",
    backgroundImage: backgroundimage5,
    description: "Detailed description of PDF Accessibility services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1:
      "PDF ACCESSIBILITY - Making every document accessible to everyone, everywhere",
    servicedescription1:
      "Delve into the realm of inclusive digital communication with our PDF accessibility services. We pledge our expertise to ensure that your PDF documents are accessible to all, including those with diverse abilities. Through meticulous attention to detail and adherence to accessibility standards of year, we enhance the usability of PDFs for screen readers and assistive devices.",
    listItems1: [
      "Accessibility First Approach",
      "Advanced Tagging and Structuring",
      "Interactive Features Support",
    ],
    section1img1: pdf1,
    section1img2: pdf2,
    section1img3: pdf3,
    section1img4: pdf4,

    serviceheading2: "Why PDF ACCESSIBILITY",
    servicedescription2:
      "In our noble pursuit of inclusivity, we strive to ensure that every piece of information within your PDFs is perceivable, operable, and understandable by all users. Our mission transcends merely compliance; It champions the ethos of universal access, empowering individuals to navigate and comprehend digital content without barriers.",
    listItems2: [
      "Expertise in Accessibility Standards ",
      "Customized Solutions ",
      "Comprehensive Accessibility Checks ",
      "Legal Compliance ",
      "Improved User Experience",
    ],
    section2img1: pdf5,
    section2img2: pdf6,
    section2img3: pdf7,
    section2img4: pdf8,
  },
  "digital-marketing-services": {
    title: "DIGITAL MARKETING",
    headTitle: "Digital Marketing Services",
    backgroundImage: digitalmarketing_banner,
    description: "Detailed description of Digital Marketing services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1: "Your success story, powered by Digital Marketing:",
    servicedescription1:
      "Digital marketing, like the minstrels of old who spread stories far and wide, seeks to amplify the voices of businesses across the vast expanse of the Internet. With strategies born of insight and cunning, it aims to capture the attention of digital citizens and guide them to the threshold of commerce. Our digital marketing services are designed to elevate your brand, drive traffic, and increase engagement. With a team of seasoned experts, we specialize in crafting tailored campaigns that align with your business goals.",
    listItems1: [
      "SEARCH ENGINE OPTIMIZATION(SEO)",
      "SEARCH ENGINE MARKETING (SEM)",
      "SOCIAL MEDIA MARKETING(SMM)",
      "CONTENT MARKETING",
      "EMAIL MARKETING",
      "GOOGLE ADSENSE",
    ],
    section1img1: digitalmarketing1,
    section1img2: digitalmarketing2,
    section1img3: digitalmarketing3,
    section1img4: digitalmarketing4,

    serviceheading2: "Why Choose Us for Digital Marketing?",
    servicedescription2:
      "Our professional team creates unique strategic solutions aimed at increasing your online presence, attracting consumers’ attention, and making a purchase. By experiencing in SEO, SMM, PPC and content marketing we develop campaigns that will bring tangible outcomes. We aspire to find out and research your organizational needs and help you succeed in this competitive world. From increasing brand recognition to achieving the highest possible return on investments, Eloiacs Softwa is your ideal partner in digital marketing.",
    listItems2: [
      "Cost-effective: This method is cheap than the traditional ways of marketing and has more returns on investment.",
      "Targeted Reach: It means that you’re going to be able to hit the ground running as you precisely target your ideal audience.",
      "Measurable: It’s also capable of tracking progress in real-time and make changes and adjustments to strategies seamlessly.",
      "Engagement: Engage customers through the social media pages, emails among others.",
      "Global Reach: Grow the business to the international market at little or no expense.",
    ],
    section2img1: digitalmarketing5,
    section2img2: digitalmarketing6,
    section2img3: digitalmarketing7,
    section2img4: digitalmarketing8,
  },
  "mobile-app-development-services": {
    title: "MOBILE APP DEVELOPMENT",
    headTitle: "Mobile App Development Services",
    backgroundImage: mobileapp_banner,
    description: "Detailed description of Digital Marketing services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1: "Mobile App Development",
    servicedescription1:
      "Eloiacs Softwa Private Limited is a leading mobile app development company that offers custom-developed, high quality and effective applications to help the businesses increase their sales. We have multidimensional competence across all the stages of the app development process, including the brainstorming and prototyping stages, the actual development stage, the testing stage and the deployment of the completed app.",
    listItems1: [
      "Utilising the Android SDK and APIs for core functionalities and integrations.",
      "Implementing responsive and user-friendly UI/UX designs adhering to Material Design principles.",
      "Ensuring compatibility across various screen sizes, resolutions, and Android versions.",
      "Testing rigorously across devices to optimise performance, stability, and user experience.",
      "Utilising the Android SDK and APIs for core functionalities and integrations.",
      "Implementing responsive and user-friendly UI/UX designs adhering to Material Design principles.",
    ],
    section1img1: mobileapp1,
    section1img2: mobileapp2,
    section1img3: mobileapp3,
    section1img4: mobileapp4,

    serviceheading2: "Why Choose Eloiacs?",
    servicedescription2:
      "We create easy-to-use and attractive mobile apps designed just for you. Our skilled team stays up-to-date with the latest trends and technology, ensuring your app works smoothly on all devices. We make sure that we then listen carefully to your conception and visions. Right from the development to the deployment of the apps, we work on the quality apps that are user-centered and effective. Ensuring quality and a continued investment in your vision, it’s our job to help you build the mobile app of your dreams. Eloiacs Software is the company you should turn to when planning on dealing with mobile applications!",
    listItems2: [
      "Custom Solutions: Our professional team makes application development for different needs and purposes.",
      "User-Friendly Design: Convenience and eye catching graphics for optimal user interaction is a feature built into all of our apps.",
      "Cross-Platform Compatibility: Currently our apps are fully compatible with both iPhone and Android systems",
      "Quality Assurance: To achieve this, we rigorously test your app to make certain that your app is working as it supposed to when launched.",
    ],
    section2img1: mobileapp5,
    section2img2: mobileapp6,
    section2img3: mobileapp7,
    section2img4: mobileapp8,
  },
  "artificial-intelligence-solutions": {
    title: "ARTIFICIAL INTELLIGENCE",
    headTitle: "Artificial Intelligence Solutions for Smart Business Growth",
    backgroundImage: ai_banner,
    description: "Detailed description of Digital Marketing services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1:
      "Artificial Intelligence (Smarter decisions start with smarter technology)",
    servicedescription1:
      "Eloiacs Softwa Private Limited is an industry leading AI development company dedicated to delivering advanced solutions that help companies unlock data-driven growth and efficiency. Everyone can benefit from our AI services in order improve operations, gain valuable insights and improve business outcomes across sectors.",
    listItems1: [
      "Tailored AI Solutions",
      "Scalable AI Systems",
      "End-to-End AI Services",
      "Seamless Integration with Existing Systems",
    ],
    section1img1: artificialIntelligence1,
    section1img2: artificialIntelligence2,
    section1img3: artificialIntelligence3,
    section1img4: artificialIntelligence4,

    serviceheading2: "Why Choose Eloiacs?",
    servicedescription2:
      "Our experts develop machine learning models to analyze data, identify patterns, and deliver insights, helping you stay competitive. We offer advanced NLP services, including chatbots and sentiment analysis tools, enabling more natural and effective customer interactions. Our computer vision technology enhances security, improves quality control, and supports innovative product development across industries. We provide expert AI consulting to help you develop and implement effective AI strategies that align with your business goals.",
    listItems2: [
      "Expertise in AI Technologies",
      "Tailored Solutions",
      "Commitment to Quality and Innovation",
      "Comprehensive AI Services",
    ],
    section2img1: artificialIntelligence5,
    section2img2: artificialIntelligence6,
    section2img3: artificialIntelligence7,
    section2img4: artificialIntelligence8,
  },
  "medical-coding": {
    title: "MEDICAL CODING",
    headTitle: "Medical Coding Services",
    backgroundImage: medicalcoding_banner,
    description: "Detailed description of Digital Marketing services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1: "Medical Coding - The Art of Recording Health",
    servicedescription1:
      "In the realm of health and healing, medical coding stands as a vital thread in the tapestry of patient care. At our establishment, we pledge our expertise to the meticulous craft of medical coding, where diagnoses, treatments, and procedures are encoded with precision and care.",
    listItems1: [
      "Accurate Coding: We offer accurate medical coding to make sound billing and avoid some mistakes.",
      "Regulation Compliance: Since compliance and auditing is crucial in the field of health this coding is in compliance with the most recent rules.",
      "Experienced Team: This work is done by our certified coders who not only possess the knowledge but also deal with all the coding related issues effectively.",
      "Fast and Efficient: We scrutinize coding so as to enhance the movement of claims as well as your billing timeline.",
    ],
    section1img1: medicalcoding1,
    section1img2: medicalcoding2,
    section1img3: medicalcoding3,
    section1img4: medicalcoding4,

    serviceheading2: "Why Choose Eloiacs?",
    servicedescription2:
      "Through the mastery of coding standards and the diligent application of our skills, we ensure that each entry in the medical record is accurate and comprehensive. Thus, we uphold the sacred trust of documenting health journeys with honour and excellence, supporting the quest for healing and well-being.",
    listItems2: [
      "Modern Tools: We employ the modern technology that enable efficient and proper coding to be produced.",
      "Secure Data: We maintain patient confidentiality by employing the best security measures to our patient’s details.",
      "Ongoing Help: Policies Regarding Coding Standards: We give unlimited assistance and updates to policies and regulations in coding standards.",
      "Reduced Errors: Being cautious helps in the calculation of the probability of mistake occurrence thus reducing the frequency of claim rejection.",
      "Better Revenue Management: Effective coding ensures that you optimize on your reimbursements as well as ease the billing process.",
    ],
    section2img1: medicalcoding5,
    section2img2: medicalcoding6,
    section2img3: medicalcoding7,
    section2img4: medicalcoding8,
  },
  "xml-conversion": {
    title: "XML CONVERSION",
    headTitle: "XML Conversion",
    backgroundImage: xmlconversion_banner,
    description: "Detailed description of Digital Marketing services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1: "XML CONVERSION - Turning complexity into clarity",
    servicedescription1:
      "Our guild of skilled artisans brings together the wisdom of antiquity with the tools of modernity, blending tradition with innovation to serve the needs of digital conversion. We pledge unwavering dedication to maintaining the integrity of the manuscripts while harnessing the transformative power of XML for digital archives and beyond.",
    listItems1: [
      "Accurate Conversion: We make sure that when converting documents to XML, no loss of data occurs in the translation process.",
      "Structured Data: As I have already mentioned, our process helps to create a well-structured, consistent structure of the content you are actually going to use.",
      "Multiple Formats: Documents, spreadsheets, and a lot of other file formats are converted into XML by us.",
      "Enhanced Accessibility: XML files are very portable, meaning that they can be accessed and used by any number of applications and on any platform.",
      "Quality Assurance: The team scans your XML files for quality and accuracy to properly generate real, high-quality results.",
    ],
    section1img1: xml1,
    section1img2: xml2,
    section1img3: xml3,
    section1img4: xml4,

    serviceheading2: "Why Choose Eloiacs!",
    servicedescription2:
      "We specialize in providing accurate and efficient XML conversion services tailored to your needs.  With the help of our highly qualified specialists, your data is transformed correctly in XML with all the metadata preserved. We appreciate the necessity of well-structured data and it is why issues of creating clear and consistent XML files are in the center of our attention.",
    listItems2: [
      "Custom Solutions: Our XML conversion services are as flexible as your project needs and specifics thereof.",
      "Ongoing Support: Conversion is a process which we undertake with the client, giving as much assistance as needed after the process is complete and before the changes take place.",
      "Cost-Effective: Our XML conversion services are affordable while at the same time offering great value.",
    ],
    section2img1: xml5,
    section2img2: xml6,
    section2img3: xml7,
    section2img4: xml8,
  },
  "bpo-services": {
    title: "BPO SERVICES",
    headTitle: "BPO Services",
    backgroundImage: bposervices_banner,
    description: "Detailed description of Digital Marketing services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1: "BPO Services - Your partner in efficiency and growth",
    servicedescription1:
      "We pledge to enhance thy enterprise with efficiency and expertise. We specialize in managing administrative tasks, providing impeccable customer care, securing the data with mastery, and supporting the technological needs with unwavering commitment. Trust in our tradition of excellence and let us propel the business towards newfound prosperity through strategic outsourcing.",
    listItems1: [
      "Cost-Effective Solutions: This is effective in cutting on operational costs because we assign ourselves non-core activities.",
      "Skilled Professionals: The team comprises workers who are well trained and have vast experience in different business operations.",
      "Focus on Core Business: So, you can focus on these core activities while we take care of your BPO requirements.",
      "Flexible Services: We have tailored-made services depending on your needs and the extent of your project.",
      "Improved Efficiency: This brings efficiency to your operation for the benefit of your business.",
    ],
    section1img1: bpo_services1,
    section1img2: bpo_services2,
    section1img3: bpo_services3,
    section1img4: bpo_services4,

    serviceheading2: "PARTNER WITH US!",
    servicedescription2:
      "In our distinguished establishment, we offer bespoke Business Process Outsourcing (BPO) services, catering to the diverse needs of esteemed enterprises seeking efficiency and excellence in every facet of their operations. With a commitment to meticulous attention to detail and a steadfast dedication to quality, we specialize in a range of BPO solutions tailored to optimize workflows and enhance productivity.",
    listItems2: [
      "Quick Scalability: It is highly flexible in terms of the expansions and contractions depending on the requirements that are needed at a given time.",
      "Quality Assurance: Adhering to high standards, we guarantee our services’ quality and demand uncompromising performance.",
      "24/7 Support: We have round-the-clock services in order to cater to the needs of the business organizations.",
      "Strong Data Security: To ensure the security of users’ personal information, it is at the forefront of our concern.",
    ],
    section2img1: bpo_services5,
    section2img2: bpo_services6,
    section2img3: bpo_services7,
    section2img4: bpo_services8,
  },
  "website-development-services": {
    title: "WEBSITE DEVELOPMENT",
    headTitle: " Professional Website Development Services",
    backgroundImage: websitedev_banner,
    description: "Detailed description of Digital Marketing services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1: "Your vision, our code - bringing ideas to life online",
    servicedescription1:
      "Eloiacs Softwa Private Limited is one of the most successful web development companies in giving you the best website that showcases your business’s dreams and aspirations. We have professional Web designers and developers who dedicate their efforts to creating professional website designs that fit the client’s needs within the parameters of style & functionality & Usability.",
    listItems1: [
      "Custom Websites: Our websites are custom made to suit the specific needs of the business as well as the company’s image and identity.",
      "Mobile-Friendly Design: The websites look good irrespective of whether you are using a mobile device or a PC.",
      "Latest Technology: Utilizing today’s technology, we create websites that are efficient, safe, and stable.",
      "Easy to Use: We create web designs that your visitors will easily understand and have a good usability with when on your website.",
      "SEO-Ready: All our websites are SEO friendly and will attract more visitors to your site.",
    ],
    section1img1: wesbitedev1,
    section1img2: wesbitedev2,
    section1img3: wesbitedev3,
    section1img4: wesbitedev4,

    serviceheading2: "Customer Satisfaction",
    servicedescription2:
      "We are committed to rendering excellent service and fostering enduring bonds with our clients.Behold the paragon of web sophistication with our meticulously crafted website development services. Our expert proficiency spans a myriad of tongues, including HTML, CSS, JavaScript, and Type Script. Behind the veil, our mastery extends to essential tools such as Web Pack, Babel, and npm/yarn, ensuring seamless performance and stalwart functionality.",
    listItems2: [
      "Flexible Process: We have a policy of customer satisfaction hence we develop and design the website to fit your needs and specifications.",
      "Complete Service: Our company is capable of providing your website from the conceptual stage until its launching and further.",
      "Fast & Secure: You advance in our services by achieving faster webpage speed to help your website defense itself against security risks.",
      "Easy Content Management: This aspect will allow you to modify the content of your Web site through a well-designed system.",
      "Ongoing Support: Welcome to the post-launch phase; don’t forget that we have tools and services to help ensure your website stays online.",
    ],
    section2img1: wesbitedev5,
    section2img2: wesbitedev6,
    section2img3: wesbitedev7,
    section2img4: wesbitedev8,
  },
  "e-publishing": {
    title: "E -PUBLISHING",
    headTitle: "E-Publishing Services",
    backgroundImage: backgroundimage6,
    description: "Detailed description of Digital Marketing services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1: "E - Publishing",
    servicedescription1:
      "Convert manuscripts, novels, essays, and other literary works into ePub format while maintaining the aesthetic and structural integrity of the original. Digitize your content with confidence and reach a broader audience with our professional ePub conversion services. We combine technical expertise with a passion for preserving the integrity and readability of your original content. Our team of skilled professionals handles everything from formatting text and images to ensuring metadata compliance and validating ePub files for distribution.",
    listItems1: [
      "Seamless Conversion: Specifically we digitize your files to the usual e-book formats such as EPUB, MOBI and PDF.",
      "Professional Formatting: In essence, our team helps your eBooks have a good feel and aesthetic value.",
      "Multi-Platform Compatibility: Our aim is to ensure your eBooks are Furthermore, we ensure your eBooks are compatible with all devices and platforms.",
      "Custom Design Options: We also provide you with options in design to complement your branding and preferred style.",
      "Quality Assurance: It is important for us to assure that there are no mistakes in our sold eBooks and that’s why we carefully scrutinize the purchased products.",
    ],
    section1img1: e_publishing1,
    section1img2: e_publishing2,
    section1img3: e_publishing3,
    section1img4: e_publishing4,
  },
  "type-setting": {
    title: "TYPE-SETTING",
    headTitle: "Type-Setting Services",
    backgroundImage: typesetting_banner,
    description: "Detailed description of Digital Marketing services",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1:
      "Type Setting - Every detail matters—making your content look its best",
    servicedescription1:
      "If you need better-looking material, we have provided professional typesetting services to help make the content less cluttered. Regardless of whether it is a book, report, or other type of eBook, we are able to tackle the layout and the fonts used, as well as designing, in order to give it the final touch of professionalism. We make sure to format everything neat and clean and to make the format eye-friendly.",
    listItems1: [
      "Clean, Professional Look: We ensure therefore that your content is well formatted and easily washes through the eyes.",
      "Custom Layouts: Our layouts are made according to the requirements of a certain project.",
      "Detail-Oriented: We pay attention to such aspects as space, font, and alignment of text in order to make it look as professional as possible.",
      "For Print & Digital: Our typesetting of printed materials as well as electronic formats is perfect.",
      "Quick Service: We ensure, for instance, that typesetting is done very fast and without compromise to the quality despite delay factors.",
      "Great Font Choices: Thus, we select the most suitable fonts that would make the content as appealing as possible and as easy to read as possible.",
    ],
    section1img1: typediting1,
    section1img2: typediting2,
    section1img3: typediting3,
    section1img4: typediting4,
  },
  "web-accessibility": {
    title: "WEB ACCESSIBILITY",
    headTitle: "Web-Accessibility Services",
    backgroundImage: webaccessibility_banner,
    description: "Detailed description of Digital Marketing services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1: "Designing for accessibility is designing for everyone.",
    servicedescription1:
      "We ensure your website is usable by each individual reaching out to it regardless of their disability. We ensure that we conform to current standards, which means that your site can easily be used with features such as screen readers and with the keyboard. We also change features such as color contrast for better visibility.",
    listItems1: [
      "Inclusive Design: Your website that we design is friendly to every person, including those with a disability",
      "WCAG Compliance: It also guarantees legal compliance of your site to Web Content Accessibility Guidelines (WCAG).",
      "Screen Reader Friendly: We make sure that your website is as integrated with screen readers as it can be.",
      "Keyboard Navigation: We make keyboard navigation better for users with disabilities who can’t use the mouse.",
      "Better Color Contrast: We make changes of color and contrast to enhance visibility and accommodate all the users.",
    ],
    section1img1: web_accessibility1,
    section1img2: web_accessibility2,
    section1img3: web_accessibility3,
    section1img4: web_accessibility4,
  },
  "word-accessibility": {
    title: "WORD ACCESSIBILITY",
    headTitle: "Word Accessibility Services",
    backgroundImage: wordaccessibility_banner,
    description: "Detailed description of Digital Marketing services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1:
      "Word Accessibility - Every document should be a gateway, not a barrier.",
    servicedescription1:
      "We focus on improving your documents’ readability so that everyone, including disabled persons, can comprehend your content. Depending on the type of document, we enhance conventional text documents to meet the specified accessibility guidelines, like WCAG and PDF/UA. It is a way of not only satisfying legal compliance needs while maintaining the company’s compliance and needs, but also to create more and improved access to users with disabilities for all of the products.",
    listItems1: [
      "Easy for Everyone: It is also ensured that your documents are clear for all users, including disabled users in some cases.",
      "Follow the Rules: Also, we make certain that your work is ADA compliant on the grounds that your documents must be accessible to all.",
      "Simple Navigation: To ensure that, we optimize headings and lists with the aim of facilitating efficient searching of the material by the users.",
      "Descriptive Image Text: We provide textual descriptions of/for images to come in handy for the visually impaired whenever they are reading your content.",
      "Clear Formatting: We align all our texts to the left to enhance appearance and thus increase usability.",
    ],
    section1img1: word_accessibility1,
    section1img2: word_accessibility2,
    section1img3: word_accessibility3,
    section1img4: word_accessibility4,
  },
  "copy-editing": {
    title: "COPY EDITING",
    headTitle: "Copy Editing Services",
    backgroundImage: copyediting_banner,
    description: "Detailed description of Digital Marketing services.",
    sectionnumber1: "01",
    sectionnumber2: "02",
    sectionnumber3: "03",
    serviceheading1:
      "Copy Editing - Clarity is key—let’s refine your message together.",
    servicedescription1:
      "We assist you with professional copy writing services to ensure that your texts are well polished and easy to understand. Our expert editors review your work and correct the grammatical errors, punctuation, tone and make it so simple to comprehend. Regardless of whether you write articles, reports, or eBooks, we pay special attention to enhancing the text while preserving your tone at the same time.",
    listItems1: [
      "Clarity and Precision: Each paper written is made comprehensible by our team to the target and possibly widest audience.",
      "Grammar and Punctuation Check: Action/repair and Editing stage: We are able to correct any grammatical or punctuation mistakes.",
      "Consistent Style: We keep your writing style and tone under check and aligned to the requirements of your project.",
      "Attention to Detail: Our editors pay close attention to all the aspects to improve the general quality of your writing.",
      "Timely Delivery: We have short turnarounds to enable us deliver papers within the set date.",
    ],
    section1img1: copyediting1,
    section1img2: copyediting2,
    section1img3: copyediting3,
    section1img4: copyediting4,
  },
};

const OurProject = () => {
  const { name } = useParams();
  const service = serviceData[name];
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    // Set the page title based on the service details
    if (service) {
      document.title = `${service.headTitle}`;
    }
    window.scrollTo(0, 0); // Scroll to the top when the page loads
  }, [service]);

  if (!service) {
    return <div>Service not found</div>;
  }

  return (
    <>
      <div>
        <div
          className=" bg-cover bg-center bg-no-repeat object-cover"
          style={{
            backgroundImage: `url(${service.backgroundImage})`,
            height: "450px",
          }}
          loading="eager"
        >
          <div className="flex justify-center items-center h-full text-center text-white bg-black bg-opacity-50">
            <div>
              <p className="text-2xl px-10 md:text-3xl md:px-0 lg:text-4xl font-bold tracking-wide">
                {service.title}
              </p>
            </div>
          </div>
        </div>

        <div className="flex  flex-row p-4 md:px-20 md:pt-10 lg:pt-10 gap-6 md:gap-14 ">
          <div className="  mt-5 mb-5 md:mb-0 rounded-full ">
            <strong>
              <p className="text-xl md:text-4xl lg:text-5xl justify-center bg-orange-500 text-center text-white p-4 md:p-14 rounded-full">
                {service.sectionnumber1}
              </p>
            </strong>
          </div>
          <div className=" ">
            <strong>
              <motion.p
                variants={headingVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                className="text-xl md:text-3xl lg:text-4xl font-Ubuntu "
              >
                {service.serviceheading1}
              </motion.p>
            </strong>
            <p className="pt-5 text-base  md:text-base lg:text-lg font-Inter text-justify">
              {service.servicedescription1}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:px-10  text-lg gap-y-6 gap-x-4 ">
          <div className="">
            <ul className="list-none md:pb-0 md:pt-8 px-5 font-Sedan ps-10 lg:ps-20 4k:ps-64 ">
              {service.listItems1.map((item, index) => (
                <li
                  key={index}
                  className="text-base lg:text-lg pb-4 font-Inter flex items-start"
                >
                  <span
                    className="inline-block w-5 h-5 mr-2 bg-no-repeat bg-contain mt-1 shrink-0"
                    style={{ backgroundImage: `url(${rightarrowbullet})` }}
                  ></span>
                  <span className="flex-1">{item}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-row gap-4 justify-center items-center p-4 md:p-0 ">
            <div className="flex flex-col items-center md:items-start ">
              {" "}
              {/* Center images on mobile */}
              <img
                className="hover:scale-105 duration-500 transition-transform w-[250px] h-[150px] object-cover"
                src={service.section1img1}
                alt="Not Found"
                loading="eager"
              />
              <img
                className="pt-4 hover:scale-105 duration-500 transition-transform w-[250px] h-[150px] object-cover"
                src={service.section1img2}
                alt="Not Found"
                loading="eager"
              />
            </div>
            <div className="flex flex-col items-center md:items-start ">
              {" "}
              {/* Center images on mobile */}
              <img
                className="hover:scale-105 duration-500 transition-transform w-[250px] h-[150px] object-cover"
                src={service.section1img3}
                alt="Not Found"
                loading="eager"
              />
              <img
                className="pt-4 hover:scale-105 duration-500 transition-transform w-[250px] h-[150px] object-cover"
                src={service.section1img4}
                alt="Not Found"
                loading="eager"
              />
            </div>
          </div>
        </div>

        {name !== "e-publishing" &&
          name !== "type-setting" &&
          name !== "web-accessibility" &&
          name !== "word-accessibility" &&
          name !== "copy-editing" && (
            <>
              <div className="flex  flex-row p-4 md:px-20 md:pt-10 lg:pt-10 gap-6 md:gap-14 ">
                <div className=" mt-5 mb-5 md:mb-0 rounded-full ">
                  <strong>
                    <p className="text-xl md:text-4xl lg:text-5xl justify-center bg-orange-500 text-center text-white p-4 md:p-14 rounded-full">
                      {service.sectionnumber2}
                    </p>
                  </strong>
                </div>
                <div>
                  <strong>
                    <motion.p
                      variants={headingVariants}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      className="text-xl md:text-3xl lg:text-4xl  font-Ubuntu"
                    >
                      {service.serviceheading2}
                    </motion.p>
                  </strong>
                  <p className="pt-5 text-base  md:text-base lg:text-lg font-Inter text-justify">
                    {service.servicedescription2}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 md:px-10 gap-y-6 gap-x-3 justify-center items-center ">
                <div className="flex flex-row gap-4 justify-center items-center p-4 md:p-0 ">
                  <div className="flex flex-col items-center md:items-start ">
                    {" "}
                    {/* Center images on mobile */}
                    <img
                      className="hover:scale-105 duration-500 transition-transform w-[250px] h-[150px]"
                      src={service.section2img1}
                      alt="Not Found"
                      loading="eager"
                    />
                    <img
                      className="pt-4 hover:scale-105 duration-500 transition-transform w-[250px] h-[150px]"
                      src={service.section2img2}
                      alt="Not Found"
                      loading="eager"
                    />
                  </div>
                  <div className="flex flex-col items-center md:items-start  ">
                    {" "}
                    {/* Center images on mobile */}
                    <img
                      className="hover:scale-105 duration-500 transition-transform w-[250px] h-[150px]"
                      src={service.section2img3}
                      alt="Not Found"
                      loading="eager"
                    />
                    <img
                      className="pt-4 hover:scale-105 duration-500 transition-transform w-[250px] h-[150px]"
                      src={service.section2img4}
                      alt="Not Found"
                      loading="eager"
                    />
                  </div>
                </div>

                <div>
                  <ul className="list-none md:pt-8 pt-2 px-5 font-Sedan text-lg  ps-10 md:ps-0">
                    {service.listItems2.map((item, index) => (
                      <li
                        key={index}
                        className="pb-4 font-Inter text-base lg:text-lg flex items-start"
                      >
                        <span
                          className="inline-block w-5 h-5 mr-2 bg-no-repeat bg-contain mt-1 shrink-0"
                          style={{
                            backgroundImage: `url(${rightarrowbullet})`,
                          }}
                        ></span>
                        <span className="flex-1">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
          )}

        <div className="flex justify-center items-center py-4 md:py-6 lg:py-6">
          <Button onClick={toggleModal} />
        </div>
      </div>
      <DragModal open={isModalOpen} setOpen={setIsModalOpen}>
        <Form />
      </DragModal>
    </>
  );
};

export default OurProject;
