import React, { useState, useEffect, useRef } from "react";
import careerbanner from "../../assets/images/career_banner2.jpeg";
import careerfullstack from "../../assets/images/software1.png";
import careerfrontend from "../../assets/images/software3.png";
import careerdigitalmarketing from "../../assets/images/digital1.jpeg";
import careerui_ux from "../../assets/images/careerui_ux.png";
import mobileappdeveloper from "../../assets/images/mobileapp.jpeg";
import backenddeveloper from "../../assets/images/backenddeveloper.jpeg";
import frontenddeveloper from "../../assets/images/frontenddeveloper.jpeg";
import epubdeveloper from "../../assets/images/epubdeveloper.jpeg";
import qualityanalyst from "../../assets/images/qualityanalyst.jpeg";
import tester from "../../assets/images/tester.jpeg";
import fullstackdeveloper from "../../assets/images/fullstackdeveloper.jpeg";
import careerhr from "../../assets/images/career_hr.png";
import { topArrow, downArrow, leftArrow, rightArrow } from "../../assets/icons";

const jobPositions = [
  {
    id: 1,
    title: "Full Stack Web Development Internship",
    description:
      "Gain hands-on experience in full-stack web development projects.",
    image: careerfullstack,
  },
  {
    id: 2,
    title: "Front End Development Internship",
    description: "Join us to create engaging user interfaces and experiences.",
    image: careerfrontend,
  },
  {
    id: 3,
    title: "Digital Marketing Certificate Course",
    description:
      "Master digital marketing strategies to boost your career success.",
    image: careerdigitalmarketing,
  },
  {
    id: 4,
    title: "UI/UX Designer Internship",
    description:
      "Design intuitive user experiences and create engaging interfaces.",
    image: careerui_ux,
  },
  {
    id: 5,
    title: "HR Management",
    description:
      "Manage recruitment, employee relations, and performance for success.",
    image: careerhr,
  },
];

const applyJobs = [
  {
    id: 1,
    title: "Web Developer",
    experience: "1 Year and Above",
    requirement: "Any Degree.",
    image: frontenddeveloper,
  },
  {
    id: 2,
    title: "Back End Developer",
    experience: "1 Year and Above",
    requirement: "Any Degree.",
    image: backenddeveloper,
  },
  {
    id: 3,
    title: "Mobile App Developer",
    experience: "1 Year and Above",
    requirement: "Any Degree.",
    image: mobileappdeveloper,
  },
  {
    id: 4,
    title: "E-pub Developer",
    experience: "1 Year and Above",
    requirement: "Any Degree.",
    image: epubdeveloper,
  },
  {
    id: 5,
    title: "Quality Analyst",
    experience: "1 Year and Above",
    requirement: "Any Degree",
    image: qualityanalyst,
  },
  {
    id: 6,
    title: "Automated Tester",
    experience: "1 Year and Above",
    requirement: "Any Degree.",
    image: tester,
  },
  {
    id: 7,
    title: "Full Stack Developer",
    experience: "1 Year and Above",
    requirement: "Any Degree.",
    image: fullstackdeveloper,
  },
];

const Career = () => {
  useEffect(() => {
    document.title = "Join the Team at Eloiacs: Your Career Starts Here";
  }, []);

  const [showAll, setShowAll] = useState(false);


  const internshipSectionRef = useRef(null);

  const slidesToShow =
    window.innerWidth < 640 ? 1 : window.innerWidth < 768 ? 2 : 4;

  const toggleShowAll = () => {
    setShowAll(!showAll);
    if (showAll && internshipSectionRef.current) {
      internshipSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const displayedJobs = showAll ? jobPositions : jobPositions.slice(0, 6);


  const carouselRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false); // Track hover state
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    if (carouselRef.current) {
      // Check if the carousel is scrollable
      setIsScrollable(
        carouselRef.current.scrollWidth > carouselRef.current.clientWidth
      );
    }
  }, [applyJobs]); // Run this check when `careerJob` changes

  // Scroll one card width to the right
  const scrollRight = () => {
    if (carouselRef.current) {
      const firstCard = carouselRef.current.firstChild;
      carouselRef.current.scrollBy({
        left: firstCard.clientWidth + 16, // Account for the spacing
        behavior: "smooth",
      });
    }
  };

  // Scroll one card width to the left
  const scrollLeft = () => {
    if (carouselRef.current) {
      const firstCard = carouselRef.current.firstChild;
      carouselRef.current.scrollBy({
        left: -(firstCard.clientWidth + 16), // Move left
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    let scrollInterval;

    // Function to start scrolling
    const startScrolling = () => {
      scrollInterval = setInterval(() => {
        if (carouselRef.current && !isHovered) {
          // Scroll the carousel by the width of the first card
          const firstCard = carouselRef.current.firstChild;
          if (firstCard) {
            carouselRef.current.scrollBy({
              left: firstCard.clientWidth + 16, // Add spacing between cards
              behavior: "smooth",
            });

            // Reset scroll position to the start if reached the end
            if (
              carouselRef.current.scrollLeft >=
              carouselRef.current.scrollWidth - carouselRef.current.clientWidth
            ) {
              carouselRef.current.scrollTo({
                left: 0,
                behavior: "smooth",
              });
            }
          }
        }
      }, 3000);
    };

    startScrolling();

    return () => clearInterval(scrollInterval);
  }, [isHovered]);



 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        {/* Career Banner Section */}
        <div className="relative py-20 bg-black bg-opacity-10 ">
          <div
            className="absolute lg:mt-5 inset-0 bg-cover bg-center h-full"
            style={{ backgroundImage: `url(${careerbanner})` }}
          ></div>
          <div className="relative text-white py-10 pl-5 md:pl-20 lg:pl-[9rem] ">
            <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl 4k:text-5xl font-Ubuntu">
              BE PART OF OUR TEAM
            </p>
            <p className="font-semibold pt-5 md:pt-10 text-xl md:text-2xl lg:text-3xl 4k:text-4xl font-PTSansCaption">
              Join us to shape the future of technology and make an impact
            </p>
          </div>
        </div>

        {/* Explore Our Internship Programme */}
        <div ref={internshipSectionRef}>
          <p className="font-medium text-center text-xl md:text-2xl lg:text-3xl 4k:text-4xl pt-2 md:pt-7 lg:pt-14 font-Suse px-4 md:px-0  pb-5">
            Explore Our Internship Programme
          </p>
          <div className="grid-cols-1 md:grid-cols-2 grid lg:grid-cols-3 4k:grid-cols-4 gap-x-20 px-3 md:px-10 lg:px-16 xl:px-20 ">
            {displayedJobs.map((job) => (
              <div
                key={job.id}
                className="mt-8 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl hover:rounded-md pb-2.5 px-2 4k:py-10 "
              >
                <div className="flex justify-center items-center">
                  <img
                    src={job.image}
                    alt={job.title}
                    className="md:w-[53vh] md:h-[34vh] hover:rounded-t-xl cursor-default"
                  />
                </div>
                <p
                  className="font-medium text-xl 4k:text-2xl text-center py-4 font-Inter"
                  title={job.title}
                >
                  {job.title.length > 40
                    ? job.title.substring(0, 40) + "..."
                    : job.title}
                </p>
                <p
                  className="text-gray-600 text-center font-Inter 4k:text-xl"
                  title={job.description}
                >
                  {job.description.length > 56
                    ? job.description.substring(0, 56) + "..."
                    : job.description}
                </p>
              </div>
            ))}
          </div>
          <div className="flex justify-end items-end ">
            {jobPositions.length > 6 && (
              <button
                className="rounded-full border-black border bg-orange-500 text-white mr-10 p-1 font-semibold hover:bg-white hover:scale-110 hover:border-orange-500 hover:border-2"
                onClick={toggleShowAll}
              >
                {showAll ? (
                  <img src={topArrow} alt="Not Found" className="w-10 h-10" />
                ) : (
                  <img src={downArrow} alt="Not Found" className="w-10 h-10" />
                )}
              </button>
            )}
          </div>
        </div>



        <div
        className="relative overflow-hidden mt-10 "
        onMouseEnter={() => setIsHovered(true)} // Stop scrolling on hover
        onMouseLeave={() => setIsHovered(false)} // Resume scrolling when hover ends
      >
        <p className="text-center text-[#EA580C] font-bold tracking-wide text-xl md:text-2xl 4k:text-3xl pb-3 font-Suse mt-2 mb-4">
          CURRENT JOB OPENINGS
        </p>

        {/* Left Arrow */}
        {isScrollable && isHovered && (
          <button
            className="absolute hidden md:block left-16 xl:left-[6.5rem] top-1/2 transform -translate-y-1/2 z-10 bg-orange-500 bg-opacity-70 text-white px-4 py-3 rounded-full"
            onClick={scrollLeft}
          >
            &lt;
          </button>
        )}

        {/* Right Arrow */}
        {isScrollable && isHovered && (
          <button
            className="absolute hidden md:block right-16 xl:right-[6.5rem] top-1/2 transform -translate-y-1/2 z-10 bg-orange-500 bg-opacity-70 text-white px-4 py-3 rounded-full"
            onClick={scrollRight}
          >
            &gt;
          </button>
        )}

        <div
          ref={carouselRef}
          className="flex overflow-x-auto space-x-5 lg:space-x-16 xl:space-x-10 p-5 mx-2 md:mx-20 lg:mx-28 xl:mx-32 scrollbar-hidden "
        >
          {applyJobs.map((job) => (
            <div
              key={job.id}
              className="min-w-[250px] bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105"
            >
              <img
                src={job.image}
                alt="Not Found"
                // alt={job.imageUrl}
                className="w-64 h-48 object-cover rounded-lg"
              />
              <div className="p-4">
                <p className="font-medium text-base lg:text-xl 4k:text-2xl pb-2 font-Inter" title={job.title}>
                  {job.title.length > 20
                    ? job.title.substring(0, 20) + "..."
                    : job.title}
                </p>
                <p className="text-gray-600 text-base 4k:text-lg font-Inter">
                  Work Experience : {job.experience}
                </p>
                <p className="text-gray-600 font-Inter text-base 4k:text-lg" title={job.requirement}>
                  Requirement :{" "}
                  {job.requirement.length > 25
                    ? job.requirement.substring(0, 25) + "..."
                    : job.requirement}
                </p>
                <div className="mt-5 flex justify-end">
                  <button
                    className="rounded-sm px-3 py-1 hover:scale-x-110 text-[#EA580C] underline underline-offset-2 cursor-pointer font-semibold font-DMsans"
                    // onClick={() => handleApply(job.id)}
                  >
                    <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfymKi0UAwblZ6wv7t6BycjPhPkIxDZ6nu_UGaFiTC9A9zGFA/viewform"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#EA580C] underline underline-offset-2 cursor-pointer font-semibold font-DMsans"
                      >
                    <APPLY_Text />
                    </a>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

        {/* Open Job Positions */}
        <div className="py-10 ">
          <div className="">
            <div className="text-justify md:text-center px-10 md:px-10 lg:px-0">
              <p className="text-lg 4k:text-xl font-Inter italic">
                Can’t find the job you want?{" "}
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfymKi0UAwblZ6wv7t6BycjPhPkIxDZ6nu_UGaFiTC9A9zGFA/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-orange-500 cursor-pointer font-semibold"
                >
                  <MatrixText />
                </a>{" "}
                on our Candidate Portal and get notified when new roles that
                match your skills open up.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;

const TARGET_TEXT = "REGISTER HERE";
const CYCLES_PER_LETTER = 2;
const SHUFFLE_TIME = 50;

const CHARS = "!@#$%^&*():{};|,.<>/?";

const MatrixText = () => {
  const intervalRef = useRef(null);
  const [text, setText] = useState(TARGET_TEXT);

  const shuffle = () => {
    let pos = 0;
    intervalRef.current = setInterval(() => {
      const shuffled = TARGET_TEXT.split("")
        .map((char, index) => {
          if (pos / CYCLES_PER_LETTER > index) {
            return char;
          }

          const randomCharIndex = Math.floor(Math.random() * CHARS.length);
          const randomChar = CHARS[randomCharIndex];

          return randomChar;
        })
        .join("");

      setText(shuffled);
      pos++;

      if (pos >= TARGET_TEXT.length * CYCLES_PER_LETTER) {
        stopShuffle();
      }
    }, SHUFFLE_TIME);
  };

  const stopShuffle = () => {
    clearInterval(intervalRef.current || undefined);
    setText(TARGET_TEXT);
  };
  return (
    <span
      className="font-mono"
      onMouseEnter={shuffle}
      onMouseLeave={stopShuffle}
    >
      {text}
    </span>
  );
};


const TARGET_TEXT1 = "APPLY";
const CYCLES_PER_LETTER1 = 2;
const SHUFFLE_TIME1 = 50;

const CHARS1 = "!@#$%^&*():{};|,.<>/?";

const APPLY_Text = () => {
  const intervalRef = useRef(null);
  const [text, setText] = useState(TARGET_TEXT1);

  const shuffle = () => {
    let pos = 0;
    intervalRef.current = setInterval(() => {
      const shuffled = TARGET_TEXT1.split("")
        .map((char, index) => {
          if (pos / CYCLES_PER_LETTER1 > index) {
            return char;
          }

          const randomCharIndex = Math.floor(Math.random() * CHARS.length);
          const randomChar = CHARS[randomCharIndex];

          return randomChar;
        })
        .join("");

      setText(shuffled);
      pos++;

      if (pos >= TARGET_TEXT1.length * CYCLES_PER_LETTER1) {
        stopShuffle();
      }
    }, SHUFFLE_TIME1);
  };

  const stopShuffle = () => {
    clearInterval(intervalRef.current || undefined);
    setText(TARGET_TEXT1);
  };
  return (
    <span
      className="font-mono"
      onMouseEnter={shuffle}
      onMouseLeave={stopShuffle}
    >
      {text}
    </span>
  );
};
