import { useEffect, useRef, useState } from "react";
import FlipText from "../../components/FlipText";
import FlipAnimatedText from "../../components/FlipAnimatedText";
import Button from "../../components/Button";
import DragModal from "../../components/DragModal";
import { heroApp, heroSubCircleApp } from "../../constants";
import { AI } from "../../assets/icons";
import { motion } from 'framer-motion';
import "./Hero.css";
import Form from "./Form";
import OrbitaryCircle from "../../components/OrbitaryCircle";

const Hero = () => {
  const blockContainerRef = useRef(null);
  const blockSize = 50;
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  // Update: Adjust screen width to include both mobile and tablet
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(window.innerWidth <= 1024);
  const words = ["Innovative", "Flexibility", "Growth"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  // Update: Adjust the resize logic to account for tablets
  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTablet(window.innerWidth <= 1024); // Mobile and tablet screens (<=1024px)
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobileOrTablet) {
      const timer = setInterval(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }, 3000); // Change word every 3 seconds

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [isMobileOrTablet]);

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const numCols = Math.ceil(screenWidth / blockSize);
    const numRows = Math.ceil(screenHeight / blockSize);
    const numBlocks = numCols * numRows;

    function createBlocks() {
      const blockContainer = blockContainerRef.current;
      for (let i = 0; i < numBlocks; i++) {
        const block = document.createElement("div");
        block.classList.add("block");
        block.dataset.index = i;
        block.addEventListener("mousemove", highlightRandomNeighbors);
        blockContainer.appendChild(block);
      }
    }

    function highlightRandomNeighbors(event) {
      const blockContainer = blockContainerRef.current;
      const index = parseInt(event.target.dataset.index);
      const neighbors = [
        index - 1,
        index + 1,
        index - numCols,
        index + numCols,
        index - numCols - 1,
        index - numCols + 1,
        index + numCols - 1,
        index + numCols + 1,
      ].filter(
        (i) =>
          i >= 0 &&
          i < numBlocks &&
          Math.abs((i % numCols) - (index % numCols)) <= 1
      );
      event.target.classList.add("highlight");
      setTimeout(() => {
        event.target.classList.remove("highlight");
      }, 500);

      shuffleArray(neighbors)
        .slice(0, 1)
        .forEach((nIndex) => {
          const neighbor = blockContainer.children[nIndex];
          if (neighbor) {
            neighbor.classList.add("highlight");
            setTimeout(() => {
              neighbor.classList.remove("highlight");
            }, 500);
          }
        });
    }

    function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }

    createBlocks();
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="blocks-container">
        <div id="blocks" ref={blockContainerRef} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 h-screen items-center place-content-center mt-0 md:mt-10 lg:mt-10 xl:mt-3 overflow-hidden">
        <div>
          <div className="ms-7 md:ms-10 lg:ms-[4.5rem] 4k:ms-24 font-Suse">
            <FlipText>Enhance</FlipText>
          </div>
          
          <p className="relative ms-8 md:ms-12 lg:ms-[5rem] md:mt-1 4k:ms-28 text-3xl text-white lowercase sm:text-5xl md:text-3xl lg:text-4xl 4k:text-9xl pointer-events-none font-Play font-medium">
            Your <span className="text-orange-600 font-semibold">Future</span>
          </p>

          {isMobileOrTablet ? (
            // <FlipAnimatedText><p className="relative ms-5 mt-3 md:ms-12 md:mt-12 4k:ms-32 text-3xl 4k:text-5xl text-white font-black uppercase pointer-events-none">
            //   <span
            //     className={`fade-in ${currentWordIndex === 0 ? 'visible' : 'hidden'}`}
            //   >
            //     {words[0]}
            //   </span>
            //   <span
            //     className={`fade-in ${currentWordIndex === 1 ? 'visible' : 'hidden'}`}
            //   >
            //     {words[1]}
            //   </span>
            //   <span
            //     className={`fade-in ${currentWordIndex === 2 ? 'visible' : 'hidden'}`}
            //   >
            //     {words[2]}
            //   </span>
            // </p></FlipAnimatedText>
            <FlipAnimatedText  words={["Innovative", "Flexibility", "Growth"]}/>
          ) : (
            <p className="relative ms-8 mt-3 md:ms-12 lg:ms-[5rem] md:mt-12 4k:ms-32 text-2xl lg:text-3xl 4k:text-6xl text-white font-black uppercase pointer-events-none">
              Innovative &middot; Flexibility &middot; Growth
            </p>
          )}

          <div className="mt-7 ms-7 md:ms-10 lg:ms-[4.5rem] 4k:ms-28 4k:mt-10 px-2 flex justify-start">
            <Button onClick={toggleModal} />
          </div>
        </div>
        <div className="pointer-events-none md:block hidden md:-ms-10 lg:-ms-0">
          {/* <motion.div className='relative md:-left-9 lg:left-[3rem] xl:left-1/4 flex w-[20rem] lg:w-[19rem] xl:w-[25rem] 4k:w-[38rem] aspect-square border border-1 rounded-full -translate-x-1/2 scale:75 md:scale-100' animate={{ rotate: -330 }} transition={{ repeat: Infinity, duration: 15, ease: 'linear' }}>
            <div className="flex w-full h-full justify-center items-center">
              <motion.div className="relative w-60 aspect-square m-auto border  border-[#252134] rounded-full" animate={{ rotate: 330 }} transition={{ repeat: Infinity, duration: 5, ease: 'linear' }}>
                {heroSubCircleApp.map((app, i) => (
                  <div
                    key={app.id}
                    className="absolute top-1/2 left-1/2 flex items-center justify-center md:w-[2.3rem] lg:w-[2.5rem] xl:w-[3rem] 4k:w-[5rem] 4k:h-[5rem] md:h-[2.3rem] lg:h-[2.5rem] xl:h-[3rem] bg-3 border border-white rounded-full"
                    style={{
                      transform: `translate(-50%, -50%) rotate(${i * (360 / heroSubCircleApp.length)}deg) translateY(7rem) rotate(-${i * (360 / heroSubCircleApp.length)}deg)`,
                    }}
                  >
                    <img
                      className="m-auto"
                      src={app.icon}
                      width={app.width}
                      height={app.height}
                      alt={app.title}
                    />
                  </div>
                ))}
                <div className="flex items-center justify-center w-full h-full bg-transparent rounded-full">
                  <div className="bg-black p-5 rounded-full">
                    <img src={AI} width={40} height={40} alt="AI" />
                  </div>
                </div>
              </motion.div>
            </div>

            <ul>
              {heroApp.map((app, i) => (
                <li
                  key={app.id}
                  className="absolute top-0 left-1/2 h-1/2 -ml-[1.6rem] origin-bottom "
                  style={{ transform: `rotate(${i * 45}deg)` }}
                >
                  <div
                    className="relative -top-[1.6rem] flex md:w-[2.3rem] lg:w-[2.5rem] xl:w-[3.2rem] md:h-[2.3rem] lg:h-[2.5rem] xl:h-[3.2rem] 4k:w-[5rem] 4k:h-[5rem] bg-3 border border-white rounded-full"
                    style={{ transform: `rotate(-${i * 45}deg)` }}
                  >
                    <img
                      className="m-auto"
                      width={app.width}
                      height={app.height}
                      src={app.icon}
                      alt={app.title}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </motion.div> */}


          <OrbitaryCircle />
        </div>
      </div>
      <DragModal open={isModalOpen} setOpen={setIsModalOpen}>
        <Form />
      </DragModal>
    </>
  );
};

export default Hero;
