import React, { useState, useEffect } from "react";
import Button from "../../components/Button";
import DragModal from "../../components/DragModal";
import Form from "../hero/Form";
import {
  Email,
  Mobile,
  location,
  Facebook,
  Instagram,
  Youtube,
  LinkedIn,
} from "../../assets/icons";

const ContactPage = () => {

  const [hoveredId, setHoveredId] = useState(null);

  
  useEffect(() => {
    document.title = "Contact Us - Eloiacs Software Pvt Ltd";
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Contact info data
  const contactData = [
    {
      icon: Email,
      link: "mailto:info@eloiacs.com",
      text: "info@eloiacs.com",
      isLink: true,
    },
    {
      icon: Mobile,
      text: "04652 250260, +91 94870 10505",
      isLink: false,
    },
    {
      icon: location,
      link: "https://www.google.com/maps/place/Eloiacs+Softwa+Private+Limited+(NAGERCOIL)/@8.1735105,77.424531,17z/data=!3m1!4b1!4m6!3m5!1s0x4e308bfd97ff4711:0xe065758689a1baef!8m2!3d8.1735052!4d77.4271113!16s%2Fg%2F11sj61s9gx?entry=ttu&g_ep=EgoyMDI0MDkyNC4wIKXMDSoASAFQAw%3D%3D",
      text: "Eloiacs Softwa Pvt Ltd, SBI Bank Building, Ramanputhur, Nagercoil, Tamil Nadu 629002",
      isLink: true,
    },
  ];

  // Social media links data
  const socialMediaLinks = [
    {
      id: 1,
      icon: Facebook,
      link: "https://www.facebook.com/eloiacs",
      text: "Eloiacs Softwa",
    },
    {
      id:2,
      icon: Instagram,
      link: "https://www.instagram.com/eloiacs_softwa_pvt.ltd/",
      text: "eloiacs_softwa_pvt.ltd",
    },
    {
      id: 3,
      icon: Youtube,
      link: "https://www.youtube.com/@eloiacs_softwa",
      text: "Eloiacs Softwa PVT.LTD",
    },
    {
      id: 4,
      icon: LinkedIn,
      link: "https://www.linkedin.com/company/99473854/admin/dashboard/",
      text: "Eloiacs Softwa Private Limited",
    },
  ];

  return (
    <div>
      <div className="flex  w-full">
        <div className="pt-16 md:pt-[4.5rem] lg:pt-[3.2rem] w-full">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.29001161035!2d77.42453101077287!3d8.173510501758784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4e308bfd97ff4711%3A0xe065758689a1baef!2sEloiacs%20Softwa%20Private%20Limited%20(NAGERCOIL)!5e0!3m2!1sen!2sin!4v1727765171469!5m2!1sen!2sin"
            title="Location map of Eloiacs Software Private Limited"
            className="w-full h-[200px] md:h-[300px] lg:h-[450px]"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div>
        <div className="bg-neutral-700 mt-0 py-5 md:py-16 lg:px-16 xl:px-20 ">
          <div className="text-white">
            <div className="text-2xl md:text-3xl 4k:text-5xl font-bold pt-10 md:pt-4 px-4 md:px-20 lg:px-24 xl:px-72">
              <p className="text-center px-3 md:px-0 font-Suse">
                <span className="text-orange-600">We value your business.</span>{" "}
                For any enquiries or feedback, contact our professional support
                team!
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14 pt-10 px-8 md:px-10 lg:px-0 xl:px-0 4k:gap-20 4k:px-20 ">
            {/* Contact Info Section */}
            <div className="relative border border-orange-500 border-opacity-35 pt-4 ps-4 pr-4 4k:ps-8 4k:pr-8 bg-[#2C2C2C] bg-opacity-35 text-white group transition-colors hover:duration-500 hover:bg-white hover:text-black">
              <div className="font-bold pt-9">
                <p className="text-xl 4k:text-3xl tracking-wider text-center">
                  Contact With Us
                </p>
              </div>


              <div className="flex flex-col mt-4 4k:text-xl py-6 justify-center items-center ">
  {contactData.map((contact, index) => (
    <div className="mb-4 w-full flex justify-center" key={index}> {/* Center the entire item */}
      <p className="flex w-full max-w-md items-center space-x-4"> {/* Added spacing between icon and text */}
        {contact.isLink ? (
          <a
            href={contact.link}
            className="flex w-full items-center hover:text-orange-500 hover:font-semibold hover:tracking-normal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="w-6 h-6 4k:w-7 4k:h-7 mr-3 flex-shrink-0"> 
              <img
                src={contact.icon}
                className="w-full h-full 4k:w-7 4k:h-7"  
                alt="Contact icon"
              />
            </span>
            <span className="text-base 4k:text-2xl flex-1"> 
              {contact.text}
            </span>
          </a>
        ) : (
          <span className="flex w-full items-center space-x-3">
            <span className="w-6 h-6 flex-shrink-0"> 
              <img
                src={contact.icon}
                className="w-full h-full 4k:w-7 4k:h-7"  
                alt="Contact icon"
              />
            </span>
            <span className="text-base 4k:text-2xl flex-1"> 
              {contact.text}
            </span>
          </span>
        )}
      </p>
    </div>
  ))}
</div>

            </div>

            {/* Modal Section */}
            <div className="flex justify-center items-center">
              <div className="font-bold pt-9">
                <Button onClick={toggleModal} />
              </div>
              <DragModal open={isModalOpen} setOpen={setIsModalOpen}>
                <Form />
              </DragModal>
            </div>

            {/* Social Media Section */}
            <div className="relative border border-orange-500 border-opacity-35 pt-4 ps-4 pr-4 4k:ps-8 4k:pr-8 bg-[#2C2C2C] bg-opacity-35 text-white group transition-colors hover:duration-500 hover:bg-white hover:text-black">
        <div className="font-bold pt-9">
          <p className="text-xl 4k:text-3xl tracking-wider text-center">
            Social Media Links
          </p>
        </div>

        <div className="mt-4 text-justify 4k:text-2xl py-6 flex justify-center items-center">
  <div className="flex-col justify-around items-center gap-y-5">
    {socialMediaLinks.map((social) => (
      <div className="mb-4" key={social.id}>
        <p className="flex items-center">
          <a
            href={social.link}
            className="relative flex items-center group"
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={() => setHoveredId(social.id)} // Set hovered ID on mouse enter
            onMouseLeave={() => setHoveredId(null)} // Reset hovered ID on mouse leave
          >
            <span className="mr-3">
              <img
                src={social.icon}
                className="w-5 h-5 4k:w-7 4k:h-7"
                alt="Social icon"
              />
            </span>
            <span className="relative flex-1 4k:text-2xl">
              {social.text}
              <span
                className={`absolute left-0 -bottom-0.5 h-0.5 w-full bg-orange-500 transition-transform duration-300 ${
                  hoveredId === social.id ? "scale-x-100" : "scale-x-0"
                }`}
              ></span>
            </span>
          </a>
        </p>
      </div>
    ))}
  </div>
</div>

      </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
