import React, { useState, useRef } from "react";

const CYCLES_PER_LETTER = 2; // Define this before usage
const SHUFFLE_TIME = 50; // Define this before usage
const CHARS = "!@#$%^&*():{};|,.<>/?"; // Define this before usage

const ServiceMatrixText = ({ text }) => {
  const intervalRef = useRef(null);
  const [displayText, setDisplayText] = useState(text);

  const shuffle = () => {
    let pos = 0;
    intervalRef.current = setInterval(() => {
      const shuffled = text.split("").map((char, index) => {
        if (pos / CYCLES_PER_LETTER > index) {
          return char;
        }

        const randomCharIndex = Math.floor(Math.random() * CHARS.length);
        const randomChar = CHARS[randomCharIndex];

        return randomChar;
      }).join("");

      setDisplayText(shuffled);
      pos++;

      if (pos >= text.length * CYCLES_PER_LETTER) {
        stopShuffle();
      }
    }, SHUFFLE_TIME);
  };

  const stopShuffle = () => {
    clearInterval(intervalRef.current || undefined);
    setDisplayText(text);
  };

  return (
    <span className="text-black"
      onMouseEnter={shuffle}
      onMouseLeave={stopShuffle}
    >
      {displayText}
    </span>
  );
};

export default ServiceMatrixText;
