import React from "react";
import { whatAppIcon } from "../assets/icons";

const WhatsApp = () => {
  const phoneNumber = "94860 10809";
  const message = "Hello, I would like to inquire about...";

  return (
    <div className="fixed bottom-6 right-6 md:bottom-[2rem] md:right-12 lg:bottom-[2rem] lg:right-14 z-50">
      <a
        href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
          message
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={whatAppIcon}
          className="w-10 h-10 cursor-pointer"
          alt="WhatsApp Icon"
        />
      </a>
    </div>
  );
};

export default WhatsApp;
